import React, { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom";
import { Link, Redirect } from "react-router-dom";
import firebase from "../../firebase";
import "firebase/firestore";
import "firebase/storage";
import { firebaseAuth } from "../../providers/AuthProvider";
import {
  DeleteIcon,
  EditIcon,
  VerifiedUser,
  LoadingPage,
} from "../../Constants";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardText,
  Row,
  Col,
  Button,
  Container,
} from "reactstrap";
import { toast } from "react-toastify";

import { FilePond, registerPlugin } from "react-filepond";
import { create } from "../../lib/react-doka/lib/doka.esm.min";
import "../../lib/react-doka/lib/doka.min.css";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageFilter from "filepond-plugin-image-filter";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { SRLWrapper } from "simple-react-lightbox";
import classnames from "classnames";
import { FormGroup, Label, FormText } from "reactstrap";
import { BloodlinePost } from "./partials/BloodlinePost";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageFilter,
  FilePondPluginImagePreview,
  FilePondPluginImageEdit,
  FilePondPluginImageTransform
);

const toDegrees = (radians) => parseFloat(radians || 0) * (180 / Math.PI);
const toRadians = (degrees) => parseFloat(degrees || 0) * (Math.PI / 180);

const MyProfile = () => {
  const db = firebase.firestore();
  const [files, setFiles] = React.useState([]);
  const [profilePic, setProfilePic] = useState({
    image: "/assets/images/profile-pic-placeholder.png",
    edit: false,
    default: true,
  });
  const [heroPic, setHeroPic] = useState({
    image: "/assets/images/hero-pic-placeholder.png",
    edit: false,
    default: true,
  });
  const [heroPond, setHeroPond] = useState(React.createRef());
  const [profilePond, setProfilePond] = useState(React.createRef());
  const { user, handleSignout } = useContext(firebaseAuth);
  const [userProfile, setUserProfile] = useState({});
  const [userPrivate, setUserPrivate] = useState({});

  const storage = firebase.storage().ref();
  const [uploadPath, setUploadPath] = useState({
    folder: "/" + user.uid + "/profile/",
    file: "",
  });
  const [activeTab, setActiveTab] = useState("1");
  const [userProfileLoading, setUserProfileLoading] = useState(false);
  const [heroPicLoading, setHeroPicLoading] = useState(false);
  const [profilePicLoading, setProfilePicLoading] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [archivedItems, setArchivedItems] = useState([]);
  const [pickedItems, setPickedItems] = useState([]);
  // const currentUserID = firebase.auth().currentUser?.uid;
  // const [editProfilePic, setEditProfilePic] = useState(false);
  // const [profilePicFile, setProfilePicFile] = React.useState(false);
  // const [itemTypeField, setItemTypeField] = React.useState("");
  // const itemType = "itemType";
  // const guitarBrands = "guitarBrands";

  useEffect(() => {
    if (user.isAuthenticated) {
      
      uploadProfilePic();
      uploadHeroPic();
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((u) => {
          if (u.exists) {
            setUserProfile(u.data());
            setUserProfileLoading(true);
          }
        })
        .catch((err) => {
          setUserProfileLoading(true);
        });
        firebase
        .firestore()
        .collection("usersPrivate")
        .doc(user.uid)
        .get()
        .then((u) => {
          if (u.exists) {
            setUserPrivate(u.data());
          }
        })
        .catch((err) => {
          setUserProfileLoading(true);
        });
      firebase
        .firestore()
        .collection("items")
        .where("userId", "==", user.uid)
        .where("ownership", "==", true)
        .get()
        .then((qs) => {
          if (qs.docs.length > 0) {
            let tmpItems = [];
            qs.docs.forEach((doc) => {
              let tmpItem = doc.data();
                  tmpItem.id = doc.id;
                  tmpItems.push(tmpItem);
            });
            console.log(tmpItems);
            setCurrentItems(tmpItems);
          }
        });
      firebase
        .firestore()
        .collection("items")
        .where("userId", "==", user.uid)
        .where("ownership", "!=", true)
        .get()
        .then((qs) => {
          if (qs.docs.length > 0) {
            let tmpItems = [];
            qs.docs.forEach((doc) => {
              let tmpItem = doc.data();
                  tmpItem.id = doc.id;
                  tmpItems.push(tmpItem);
            });
            setArchivedItems(tmpItems);
          }
        });
        firebase
        .firestore()
        .collection("items")
        .where('picks', 'array-contains',user.uid)
        .get()
        .then((qs) => {
          if (qs.docs.length > 0) {
            let tmpItems = [];
            qs.docs.forEach((doc) => {
              let tmpItem = doc.data();
                  tmpItem.id = doc.id;
                  tmpItems.push(tmpItem);
            });
            setPickedItems(tmpItems);
          }
        })
    }
  }, []);
  const handleSignOut = (e) => {
    e.preventDefault();
    handleSignout();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var updateProfile = firebase.functions().httpsCallable("app/updateProfile");
    updateProfile({ docid: user.uid, userProfile: userProfile,userPrivate:userPrivate }).then(function (
      result
    ) {
      console.log(result);
    });

    toggleEdit();
  };
  if (!user.isAuthenticated) return <Redirect to="/sign-in" />;
  const uploadProfilePic = () => {
    if (
      !profilePond.current || (profilePond.current && profilePond.current
        .getFiles()
        .filter((x) => x.status !== 5 && x.status !== 2).length == 0
    )) {
    setUploadPath({ folder: "/" + user.uid + "/profile/", file: user.uid });

    storage
      .child("/" + user.uid + "/profile/" + user.uid)
      .getDownloadURL()
      .then((url) => {
        setProfilePic({ image: url, edit: false });
        setProfilePicLoading(true);
      })
      .catch((err) => {
        console.log(err);
        setProfilePic({
          image: "/assets/images/profile-pic-placeholder.png",
          edit: false,
          default: true,
        });
        setProfilePicLoading(true);
      });
    } else {
      toast.warning("Files are still processing, please wait.");
    }
  };
  const uploadHeroPic = () => {
    if (
      !heroPond.current || (heroPond.current && heroPond.current
        .getFiles()
        .filter((x) => x.status !== 5 && x.status !== 2).length == 0
    )) {
    setUploadPath({ folder: "/" + user.uid + "/profile/", file: "hero_" + user.uid });

    storage
      .child("/" + user.uid + "/profile/hero_" + user.uid)
      .getDownloadURL()
      .then((url) => {
        setHeroPic({ image: url, edit: false });
        setHeroPicLoading(true);
      })
      .catch((err) => {
        console.log(err);
        setHeroPic({
          image: "/assets/images/hero-pic-placeholder.png",
          edit: false,
          default: true,
        });
        setHeroPicLoading(true);
      });
    } else {
      toast.warning("Files are still processing, please wait.");
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleEdit = () => {
    if (editProfile) setEditProfile(false);
    else setEditProfile(true);
  };
  const handleProfileChange = (e) => {
    setUserProfile({
      ...userProfile,
      [e.target.name]: e.target.value,
    });
    console.log(userProfile);
  };
  const handlePrivateChange = (e) => {
    setUserPrivate({
      ...userPrivate,
      [e.target.name]: e.target.value,
    });
    console.log(userProfile);
  };

  const server = {
    process: (fieldName, file, metadata, load, error, progress, abort) => {
  
      const task = storage
        .child(uploadPath.folder + uploadPath.file)
        .put(file, {
          contentType: file.type,
        });
      task.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snap) => {
          progress(true, snap.bytesTransferred, snap.totalBytes);
        },
        (err) => {
          error(err.message);
        },
        () => {
          load(uploadPath.file);
        }
      );
    },
    load: (source, load, error, progress, abort) => {
      progress(true, 0, 1024);
      storage
        .child(uploadPath.folder + source)
        .getDownloadURL()
        .then((url) => {
          let xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = function (event) {
            let blob = xhr.response;
            load(blob);
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch((err) => {
          console.log(err);
          error(err.message);
          abort();
        });
    },
  };
  const UpdateHeroPic = () => {
    setUploadPath({ folder: "/" + user.uid + "/profile/", file: "hero_" + user.uid });

    if (heroPic.default) {
      setHeroPic({ image: heroPic.image, edit: true, default: true });
      setFiles([]);
    } else {
      setFiles([
        {
          source: "hero_" + user.uid,
          options: {
            type: "local",
          },
        },
      ]);
      setHeroPic({ image: heroPic.image, edit: true });
    }
  };
  const DeleteHeroPic = () => {
    setUploadPath({ folder: "/" + user.uid + "/profile/", file: "hero_" + user.uid });
    storage
      .child("/" + user.uid + "/profile/hero_" + user.uid)
      .delete()
      .then(function () {
        setHeroPic({
          image: "/assets/images/hero-pic-placeholder.png",
          edit: false,
          default: true,
        });
      })
      .catch(function (error) {
        // Uh-oh, an error occurred!
      });
  };
  const ShowHeroPic = () => {
    if (!heroPic.edit) {
      return (
        <div className="pic-view">
          <img
            className="hero-pic"
            src={heroPic.image}
            alt="Bloodline Profile Hero Picture"
          />
          <div className="pic-manage">
            <i className="light sm" onClick={UpdateHeroPic}>
              <EditIcon />
            </i>
            <i
              className={heroPic.default ? "d-none" : "ml-5 light sm"}
              onClick={DeleteHeroPic}
            >
              <DeleteIcon />
            </i>
          </div>
        </div>
      );
    } else {
      return (
        <div className="hero-pic-editor pic-editor">
          <FilePond
            ref={heroPond}
            files={files}
            allowReorder={false}
            allowMultiple={false}
            maxFiles={1}
            server={server}
            onuploadfiles={() => {}}
            imageTransformOutputQuality="60"
            imageResizeTargetWidth="1920"
            imageCropAspectRatio="5:1"
            imageEditEditor={create({
              cropAspectRatioOptions: [
                {
                  label: "Hero Banner Image",
                  value: 0.20,
                },
              ],
            })}
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          />
          <div
            className="btn btn-primary pic-editor-done"
            onClick={uploadHeroPic}
          >
            DONE
          </div>
        </div>
      );
    }
  };
  const UpdateProfilePic = () => {
    setUploadPath({ folder: "/" + user.uid + "/profile/", file: user.uid });

    if (profilePic.default) {
      setProfilePic({ image: profilePic.image, edit: true });
      setFiles([]);
    } else {
      setFiles([
        {
          source: user.uid,
          options: {
            type: "local",
          },
        },
      ]);
      setProfilePic({ image: profilePic.image, edit: true });
    }
  };
  const DeleteProfilePic = () => {
    setUploadPath({ folder: "/" + user.uid + "/profile/", file: user.uid });
    storage
      .child("/" + user.uid + "/profile/" + user.uid)
      .delete()
      .then(function () {
        setProfilePic({
          image: "/assets/images/profile-pic-placeholder.png",
          edit: false,
          default: true,
        });
      })
      .catch(function (error) {
        // Uh-oh, an error occurred!
      });
  };
  const ShowProfilePic = () => {
    if (!profilePic.edit) {
      return (
        <div className="pic-view profile-pic">
          <img
            className=""
            onClick={UpdateProfilePic}
            src={profilePic.image}
            alt="Bloodline Profile Picture"
          />
          <div className="pic-manage">
            {" "}
            <i onClick={UpdateProfilePic} className="light sm">
              <EditIcon />
            </i>
            <i
              onClick={DeleteProfilePic}
              className={profilePic.default ? "d-none" : "ml-5 light sm"}
            >
              <DeleteIcon />
            </i>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pic-editor">
          <div className="profile-pic-editor">
            <FilePond
              ref={profilePond}
              files={files}
              allowReorder={false}
              allowMultiple={false}
              maxFiles={1}
              server={server}
              onuploadfiles={() => {}}
              imageResizeTargetWidth="512"
              imageResizeTargetHeight="512"
              imageTransformOutputQuality="60"
              imageCropAspectRatio="1"
              imagecropShowSize="true"
              imageEditEditor={create({
                cropAspectRatioOptions: [
                  {
                    label: "Profile",
                    value: 1,
                  }
                ],
              })}
              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
          </div>
          <div
            className="btn btn-primary pic-editor-done"
            onClick={uploadProfilePic}
          >
            DONE
          </div>
        </div>
      );
    }
  };
  if (!user.isAuthenticated) return <Redirect to="/sign-in" />;
  if (!heroPicLoading || !profilePicLoading || !userProfileLoading)
    return <LoadingPage />;

  return (
    <div>
      <ShowHeroPic />
      <div className="py-md-3 py-2"></div>
      <div className="container h-100">
        <div className="row h-100 align-items-center">
          <div className={editProfile ? "d-none":"col-md-auto col-12 align-self-start"}>
            <div className="px-lg-5 px-md-2 px-0">
              <ShowProfilePic />
            </div>
          </div>
          <div className="col-md col-12">
            <div className="pl-lg-5 pl-md-2 pl-0">
              <div className="row align-items-center">
                <div className="col-auto">
                  <h3
                    className={
                      editProfile && !userProfile.verified ? "d-none" : ""
                    }
                  ><Link to={"/" + userProfile.handle}>
                    {userProfile.handle}{" "}
                    <VerifiedUser verified={userProfile.verified} /></Link>
                  </h3>
                  <div className="row">
                    <div
                      className={
                        editProfile && userProfile.verified ? "d-none" : "col"
                      }
                    >
                      <div
                        className={
                          !editProfile || userProfile.verified
                            ? "d-none"
                            : "form-group"
                        }
                      >
                        <label className="form-label" htmlFor="handle">
                          Username
                        </label>
                        <input
                          type="text"
                          id="handle"
                          name="handle"
                          value={userProfile.handle || ""}
                          onChange={handleProfileChange}
                          className="form-control"
                        />
                        <Link
                          to=""
                          className={userProfile.verified ? "d-none" : ""}
                        >
                          REQUEST VERIFICATION
                        </Link>
                      </div>
                    </div>
                    <div className="col">
                      <div className={!editProfile ? "d-none" : "form-group"}>
                        <label className="form-label" htmlFor="emailAlt">
                          Email Address
                        </label>
                        <div>{user.user}</div>
                        <Link
                          to=""
                          onClick={handleSignOut}
                          className={user.email_verified ? "d-none" : ""}
                        >
                          VERIFY EMAIL
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md col-12">
                      <div className={!editProfile ? "d-none" : "form-group"}>
                        <label className="form-label" htmlFor="firstName">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          value={userPrivate.firstName || ""}
                          onChange={(handlePrivateChange)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md col-12">
                      <div className={!editProfile ? "d-none" : "form-group"}>
                        <label className="form-label" htmlFor="lastName">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          value={userPrivate.lastName || ""}
                          onChange={handlePrivateChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md col-12">
                      <div className={!editProfile ? "d-none" : "form-group"}>
                        <label className="form-label" htmlFor="location">
                          Location
                        </label>
                        <input
                          type="text"
                          id="location"
                          name="location"
                          value={userProfile.location || ""}
                          onChange={handleProfileChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col d-none d-md-flex">
             
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md col-12">
                      <div className={!editProfile ? "d-none" : "form-group"}>
                        <label className="form-label" htmlFor="emailAlt">
                          Alternate Email
                        </label>
                        <input
                          type="text"
                          id="emailAlt"
                          name="emailAlt"
                          value={userPrivate.emailAlt || user.user || ""}
                          onChange={handlePrivateChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md col-12">
                      <div className={!editProfile ? "d-none" : "form-group"}>
                        <label className="form-label" htmlFor="mobilePhone">
                          Mobile Phone
                        </label>
                        <input
                          type="phone"
                          id="mobilePhone"
                          name="mobilePhone"
                          value={userPrivate.mobilePhone || ""}
                          onChange={handlePrivateChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <button
                    onClick={toggleEdit}
                    className={
                      !editProfile ? "btn btn-primary btn-small" : "d-none"
                    }
                  >
                    EDIT
                  </button>
                </div>
              </div>
              <div className={editProfile ? "d-none":""}>
                {userProfile.location}
              </div>
              <Link
                to=""
                onClick={handleSignOut}
                className={editProfile ? "d-none" : ""}
              >
                logout
              </Link>
              <div className={editProfile ? "d-none" : "py-2"}></div>
              <div className={editProfile ? "d-none" : "row"}>
                <div className="col-auto">
                  <strong>{currentItems.length}</strong> Current
                </div>
                <div className="col-auto">
                  <strong>{archivedItems.length}</strong> Past
                </div>
                <div className="col-auto">
                  <strong>{pickedItems.length}</strong> Picks
                </div>
                <div className="col"></div>
              </div>
              <div className={editProfile ? "d-none" : "py-2"}></div>
              <div className={editProfile ? "d-none" : ""}>
                {userProfile.bio}
              </div>
              <div className={editProfile ? "d-none" : "py-md-4 py-0"}></div>
              <div className={!editProfile ? "d-none" : ""}>
                <div className={"form-group"}>
                  <label className="form-label" htmlFor="email">
                    BIO
                  </label>
                  <textarea
                    id="bio"
                    name="bio"
                    onChange={handleProfileChange}
                    className="form-control"
                    defaultValue={userProfile.bio || ""}
                  />
                </div>
                <div className="row">
                  <div className="col">
                    <div className={"form-group"}>
                      <label className="form-label" htmlFor="birthDate">
                        Birth Date
                      </label>
                      <input
                        type="date"
                        id="birthDate"
                        name="birthDate"
                        value={userPrivate.birthDate || ""}
                        onChange={handlePrivateChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">&nbsp;</div>
                </div>

                <FormGroup className="pl-0 pb-3" check>
                  <label className="form-label d-block">Account Privacy</label>
                  <label className="checkbox-container">
                    Prevent other users from viewing key identity information
                    from your profile.
                    <input
                      type="checkbox"
                      onChange={(e) => {setUserPrivate({
                        ...userPrivate,
                        [e.target.name]: e.target.checked,
                      });}}
                      id="privacy"
                      name="privacy"
                      value={true}
                      defaultChecked={userPrivate.privacy}
                    />
                    <span className="checkmark"></span>
                  </label>
                </FormGroup>
                <FormGroup className="pl-0 pb-3" check>
                  <label className="form-label d-block">Allow Sharing</label>
                  <label className="checkbox-container">
                    Let people share your story as messages
                    <input
                      type="checkbox"
                      onChange={(e) => {setUserPrivate({
                        ...userPrivate,
                        [e.target.name]: e.target.checked,
                      });}}
                      id="sharing"
                      name="sharing"
                      defaultChecked={userPrivate.sharing}
                    />
                    <span className="checkmark"></span>
                  </label>
                </FormGroup>

                <FormGroup className="pl-0 pb-3" check>
                  <label className="form-label d-block">
                    Communications Opt-In
                  </label>
                  <label className="checkbox-container">
                    Agree to receive news, updates and marketing from Fret 12
                    and it's affiliates.
                    <input
                      type="checkbox"
                      onChange={(e) => {setUserPrivate({
                        ...userPrivate,
                        [e.target.name]: e.target.checked,
                      });}}
                      id="optin"
                      name="optin"
                      defaultChecked={userPrivate.optin}
                    />
                    <span className="checkmark"></span>
                  </label>
                </FormGroup>
              </div>
              <div className={!editProfile ? "d-none" : ""}>
                <button onClick={handleSubmit} className={"btn btn-primary"}>
                  SAVE
                </button>
                <button onClick={toggleEdit} className={"ml-4 btn btn-default"}>
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="py-md-4 py-3"></div>

        <h3 className="text-center pb-4">GEAR</h3>
<div className="text-right"><Link to="/add">Add Item</Link></div>
        <Nav tabs className="justify-content-center">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Current
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Past
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Picks
            </NavLink>
          </NavItem>
        </Nav>
        <div className="py-3"></div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
          <Row className={currentItems.length > 0 ? "d-none":""}>
              <Col sm="12">
                <div className="text-center">
                  <h4>No current items</h4>
                  <div className="py-2"></div>
                  <Link to={"/add"} className="btn btn-primary">Add Item</Link>
                </div>
              </Col>
            </Row>
            <div className={currentItems.length === 0 ? "d-none":"row"}>
            {currentItems.map((item,i) => (
              <div className="col-lg-3 col-md-4 col-6" id={"current-post-" + i} key={i}>
                                <BloodlinePost edit={true} item={item} />
                                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId="2">
            <Row className={archivedItems.length > 0 ? "d-none":"row"}>
              <Col sm="12">
                <div className="text-center">
                  <h4>No past items</h4>
                  <div className="py-2"></div>
                  <Link to={"/add"} className="btn btn-primary">Add Item</Link>
                </div>
              </Col>
            </Row>
            <div className={archivedItems.length === 0 ? "d-none":"row"}>
            {archivedItems.map((item,i) => (
                    <div className="col-lg-3 col-md-4 col-6" id={"current-post-" + i} key={i}>
                    <BloodlinePost edit={true} item={item} />
                    </div>
                    ))}
            </div>
          </TabPane>
          <TabPane tabId="3">
            <Row className={pickedItems.length > 0 ? "d-none":""}>
              <Col sm="12">
                <div className="text-center">
                  <h4>No picked items</h4>
                  <div className="py-2"></div>
                  <Link className="btn btn-primary" to="/explore">
                    Explore
                  </Link>
                </div>
              </Col>
            </Row>
            <div className={pickedItems.length === 0 ? "d-none":"row"}>
            {pickedItems.map((item,i) => (
              <div className="col-lg-3 col-md-4 col-6" id={"picked-post-" + i} key={i}>
              <BloodlinePost item={item} />
              </div>
      ))}
            </div>
            
          </TabPane>
        </TabContent>
        <div className="py-5"></div>
      </div>
    </div>
  );
};

export default MyProfile;
