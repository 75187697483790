import React, { useEffect,  useContext } from "react";
import { Redirect,  useHistory } from "react-router-dom";
import firebase from "../../firebase";
import "firebase/firestore";
import "firebase/storage";
import { firebaseAuth } from "../../providers/AuthProvider";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
const Add = () => {
  const db = firebase.firestore();
  const { user } = useContext(firebaseAuth);
  const history = useHistory();

  useEffect(() => {
    if (user.isAuthenticated) {
      db.collection("items")
        .add({ media: [], videos: [], picks: [], userId: user.uid, ownership:false })
        .then((doc) => {
          history.push("/edit/" + doc.id);
        }, []);
    }
  });
  if (!user.isAuthenticated) return <Redirect to="/sign-in" />;
  return <div></div>;
};

export default Add;
