import React, { useState,useContext } from "react";
import firebase from "../../firebase";
import { Link, useHistory } from "react-router-dom";
import { Bloodline, LeftArrow } from "../../Constants";
import { firebaseAuth } from "../../providers/AuthProvider";

import {
  Button,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

const NavbarNav = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { user } = useContext(firebaseAuth);

  const toggleMenu = () => setIsOpen(!isOpen);
  const toggle = () => {
    if (isOpen) setIsOpen(!isOpen);
  };

  return (
    <div className="navbar-container">
      {/* <Navbar color="dark" dark expand="md" className="navbar-dark">
        <NavbarToggler onClick={toggleMenu} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mx-auto" navbar>
            <NavItem>
              <NavLink href="https://www.fret12.com/pages/guitar-supply-collections">
                Guitar Supply
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://www.fret12.com/pages/apparel">
                Apparel
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://www.fret12.com/collections/the-sound-and-the-story">
                The Sound and The Story
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://www.fret12.com/collections/resonance">
                Resonance
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://www.fret12.com/blogs/string-things">
                Journal
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={toggle} className="nav-link-active" href="/">
                Bloodline
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar> */}
      <div className="bl-navbar">
        <Navbar color="dark" dark className="navbar-dark sub-nav">
        <div className="container px-0 px-sm-3">

          <div className="bl-back-button" onClick={() => history.goBack()}>
            <i className="light md">
              <LeftArrow />
            </i>
          </div>
 
          {/* <Collapse isOpen={isOpen} navbar> */}
            <Nav className="mx-auto" navbar>
              <NavItem>
                <Link onClick={toggle} className="nav-link" to="/">
                  Feed
                </Link>
              </NavItem>
              <NavItem>
                <Link onClick={toggle} className="nav-link" to="/collections">
                  Explore
                </Link>
              </NavItem>
              <NavItem>
                <Link onClick={toggle} className="nav-link" to="/picks">
                  Picks
                </Link>
              </NavItem>
              <NavItem className="d-none d-sm-flex">
                <a className="nav-link" href="https://www.fret12.com">
                  Store
                </a>
              </NavItem>
              {/* <NavItem>
                <Link onClick={toggle} className="nav-link" to="/search">
                  Search
                </Link>
              </NavItem> */}
            </Nav>
          {/* </Collapse> */}
          <Link className="bl-profile-button" to={"/profile"}>
            <i className="light sm">
              <Bloodline />
            </i>
          </Link>
          </div>

        </Navbar>
      </div>
    </div>
  );
};
export default NavbarNav;
