import React, { useEffect, useState, useContext } from "react";
import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import firebase from "../../firebase";
import "firebase/firestore";
import "firebase/storage";
import { firebaseAuth } from "../../providers/AuthProvider";
import { VerifiedUser, Pick, PickAdd,breakpointColumnsObj} from "../../Constants";
import Masonry from 'react-masonry-css'
import {
  EmailShareButton,
  FacebookShareButton,
  PocketShareButton,
  RedditShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  PocketIcon,
  RedditIcon,
  TwitterIcon,
  // FacebookShareCount,
  // RedditShareCount
} from "react-share";

import { StoryEntry } from "./partials/StoryEntry";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import classnames from "classnames";

import { SRLWrapper } from "simple-react-lightbox";
import { BloodlinePost } from "./partials/BloodlinePost";
import { toast } from "react-toastify";
import { useWindowScroll } from "@react-hook/window-scroll";
import { useWindowSize } from "@react-hook/window-size";

const Gear = () => {
  const db = firebase.firestore();
  const { user } = useContext(firebaseAuth);
  const storage = firebase.storage().ref();
  const { id } = useParams();
  const [item, setItem] = useState({});
  const [owner, setOwner] = useState(null);
  const [cols, setCols] = useState({ media: "4", videos: "6" });
  const [feedItems, setFeedItems] = useState([]);
  const [userItems, setUserItems] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [stories, setStories] = useState([]);
  const history = useHistory();
  // const containerRef = React.useRef(null);
  // const [windowWidth, height] = useWindowSize();
  // const { offset, width } = useContainerPosition(containerRef, [
  //   windowWidth,
  //   height
  // ]);
  // // const { offset, width } = useContainerPosition(containerRef, [
  //   windowWidth,
  //   windowHeight,
  // ]);
  // const [maxWidth,setMaxWidth] = useState(1110);
  // const [maxColWidth,setMaxColWidth] = useState(360);
  
  // const positioner = usePositioner(
  //   { width:width || maxWidth, columnWidth:maxColWidth, columnGutter:8},
  //   [feedItems]
  // );
  // const { scrollTop, isScrolling } = useScroller(offset);

  // const resizeObserver = useResizeObserver(positioner);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {

  // if(windowWidth < 576) {
  //   setMaxWidth(windowWidth - 45);
  //   setMaxColWidth(maxWidth);
  // }
  // else if(windowWidth < 768)
  //   setMaxWidth(510);
  // else if( windowWidth < 992){
  //   setMaxWidth(690);
  //   setMaxColWidth(337);

  // }
  // else if(windowWidth < 1200)
  //   setMaxWidth(930);
    initItem();
  }, []);
  useEffect(() => {
    console.log(1);
    history.push("/gear/" + id);
    window.scrollTo(0, 0)
    initItem();
  }, [id]);
  const initItem = () => {
    firebase
      .firestore()
      .collection("items")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let tmpItem = doc.data();
          tmpItem.id = doc.id;
          if(tmpItem.bloodlineDefault)
            setActiveTab("2");
          if (!tmpItem.media) tmpItem.media = [];
          if (!tmpItem.videos) tmpItem.videos = [];
          setItem(tmpItem);
          setCols({
            media: tmpItem.media.length < 3 ? "4" : "4",
            videos: tmpItem.media.length < 3 ? "12" : "6",
          });
          firebase
          .firestore()
          .collection("stories")
          .where("itemId", "==", id)
          .orderBy("occured","desc")
          .get()
          .then((qs) => {
            if (qs.docs.length > 0) {
              let tmpStories = [];
                qs.docs.forEach((doc) => {
                  let tmpStory = doc.data();
                  tmpStory.id = doc.id;
                  tmpStories.push(tmpStory);
                });
                
              setStories(tmpStories);
            }
          });
          firebase
            .firestore()
            .collection("items")
            .where("userId", "!=", doc.data().userId)
            .get()
            .then((qs) => {
              if (qs.docs.length > 0) {
                let tmpItems = [];
                qs.docs.forEach((doc) => {
                  let tmpItem = doc.data();
                  tmpItem.id = doc.id;
                  tmpItems.push(tmpItem);
                });
                setFeedItems(tmpItems);
              }
            });
          firebase
            .firestore()
            .collection("items")
            .where("userId", "==", doc.data().userId)
            .orderBy("createdAt","desc")
            .limit(5)
            .get()
            .then((qs) => {
              if (qs.docs.length > 0) {
                let tmpItems = [];
                qs.docs.forEach((doc) => {
                  let tmpItem = doc.data();
                  tmpItem.id = doc.id;
                  tmpItems.push(tmpItem);
                });
                setUserItems(tmpItems);
              }
            });
          firebase
            .firestore()
            .collection("users")
            .doc(doc.data().userId)
            .get()
            .then((u) => {
              if (u.exists) {
                setOwner(u.data());
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };
  const AddPick = () => {
    if(!user || !user.isAuthenticated) {
      toast.warning("You have to be logged in to pick an item.");
      return;
    }
    if(item.userId === user.uid) {
      toast.warning("You can't pick your own item!");
      return;
    }
    var addPick = firebase.functions().httpsCallable("app/addPick");
    addPick({ itemId: item.id, userId: user.uid })
      .then((result) => {
        setItem({ ...item, picks: [...item.picks, user.uid] });
        toast.success("Added to your list of picked items.");
      })
      .catch((error) => {
        console.log(error);
        toast.warning("This is already one of your picked items.");
      });
  };
  
  const url = window.location.href;
  const MasonryCard = ({
    index,
    data: { id, model, brand, featuredMedia, picks, ownership },
    width,
  }) => (
    <div className="masonry-card">
      <BloodlinePost
        item={{
          id: id,
          featuredMedia: featuredMedia,
          brand: brand,
          model: model,
          picks: picks,
          ownership: ownership,
        }}
      />
    </div>
  );
  // if (!item) {
  //   return <div>Item not found.</div>;
  // } else {
    return (
      <div>
        <div className="py-3"></div>
        <div className="container h-100">
          <div className="row h-100 align-items-start">
            <div className="col-lg-4 col-12 align-self-start">
              <div className="pr-5">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <div
                      className={item?.picks?.length >= 0 ? "pick-view" : "d-none"}
                    >
                      <div className="align-items-center d-flex">
                        <div className="pr-2">
                          <i className="light md d-block">
                            <Pick />
                          </i>
                        </div>
                        <div>{item?.picks?.length}</div>
                      </div>
                    </div>
                  </div>
                  <div className={(!user || user.uid === item.userId) ? "d-none":"col-auto px-0"}>
                    <i onClick={AddPick} className="alt add-pick md d-block">
                      <PickAdd />
                    </i>
                  </div>
                  <div className="col"></div>
                </div>
                <div className="py-3">
                  <FacebookShareButton className="mr-1" url={url}  hashtag="#bloodline" quote={(item.year ? item.year + " ":"") + item.brand + " " + item.model}><FacebookIcon size={32} round={true}/></FacebookShareButton>
                  <TwitterShareButton className="mr-1" url={url} via={"Fret12-Bloodline"} hashtags={["bloodline","fret12"]}title={(item.year ? item.year + " ":"") + item.brand + " " + item.model}><TwitterIcon size={32} round={true}/></TwitterShareButton>
                  <PocketShareButton className="mr-1" url={url} title={(item.year ? item.year + " ":"") + item.brand + " " + item.model}><PocketIcon size={32} round={true}/></PocketShareButton>
                  <RedditShareButton className="mr-1" url={url} title={(item.year ? item.year + " ":"") + item.brand + " " + item.model}><RedditIcon size={32} round={true}/></RedditShareButton>
                  <EmailShareButton className="mr-1" url={url} subject="Shared Bloodline." body={(item.year ? item.year + " ":"") + item.brand + " " + item.model}><EmailIcon size={32} round={true}/></EmailShareButton>
                </div>
                <div className="py-2"></div>
                <h3 className="">
                  {item.year} {item.brand} {item.model}
                </h3>
                <h4 className={item.nickname ? "" : "d-none"}>
                  a.k.a. - {item.nickname}
                </h4>
                <h4>
                  <Link to={"/" + (owner ? owner.handle : "")}>
                    {owner ? owner.handle : ""}{" "}
                    <VerifiedUser
                      verified={owner ? owner.verified : false}
                      size={"xs"}
                    />
                  </Link>
                </h4>
                <div className="pt-4 pb-4 pb-md-0">{item.description}</div>
                <hr className="my-5 d-none d-lg-block" />
                <div className="py-4 d-lg-none"></div>
                <div className="d-none d-lg-block">
                <h4>More from this Collector</h4>
                {userItems.map((userItem, i) => (
                  <div key={i}>
                    <Link to={"/gear/" + userItem.id}>
                      {userItem.brand} {userItem.model}
                    </Link>
                  </div>
                ))}
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-12">
            <Nav tabs className="">
              <div className="col"></div>
          <NavItem className={activeTab === "1" ? "d-none":""}>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Gallery
            </NavLink>
          </NavItem>
          <NavItem className={activeTab === "2" ? "d-none":""}>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Bloodline
            </NavLink>
          </NavItem>
       
        </Nav>
        <div className="py-3"></div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
          <h3>Gallery</h3>
          <SRLWrapper>
                <div className={item.featuredVideo ? "row" : "d-none"}>
                  <div className="px-0 col">
                    <div className="bl-video video-wrapper">
                      <iframe
                        className="w-100"
                        src={item.featuredVideo}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className={item.featuredVideo ? "d-none" : "row"}>
                  <div className="col px-0">
                    <img
                      className="w-100 bl-media-featured"
                      src={item.featuredMedia ? item.featuredMedia.url : ""}
                      alt={item.brand + " " + item.model}
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      !item.featuredVideo ? "d-none" : "px-0 col-lg-" + cols.media + " col-md-6 col-12"
                    }
                  >
                    <img
                      className="bl-media w-100"
                      src={item.featuredMedia ? item.featuredMedia.url : ""}
                      alt={item.brand + " " + item.model}
                    />
                  </div>

                  {item.media?.map((image, i) => (
                    <div className={"px-0 col-lg-" + cols.media + " col-md-6 col-12"} key={i}>
                      <img
                        className="bl-media w-100"
                        src={image ? image.url : ""}
                        alt={item.brand + " " + item.model + " Image " + i}
                      />
                    </div>
                  ))}
                </div>
                <div className="row">
                  {item.videos?.map((video, i) => (
                    <div className={"px-0 col-" + (item.videos.length > 1 ? "6" : "12")} key={i}>
                      <div className="bl-video   video-wrapper">
                        <iframe
                          className="w-100"
                          src={video}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  ))}
                </div>
              </SRLWrapper>
          </TabPane>
          <TabPane tabId="2">
            <h3>Bloodline</h3>
            
            
          {stories?.map((story, i) => (

              <StoryEntry story={story} last={i === stories.length - 1 } key={i} i={i}/>

                  ))}          
                  
                  </TabPane>
        </TabContent>
              
            </div>
          </div>
          <div className="py-5"></div>
          <hr />
          <div className="py-5"></div>
          <Masonry
  breakpointCols={breakpointColumnsObj}
  className="my-masonry-grid"
  columnClassName="my-masonry-grid_column">
{feedItems.map((item,i) => (
   <div className="masonry-card" key={i}>
  <BloodlinePost item={{ id:item.id, featuredMedia:item.featuredMedia, brand:item.brand, model:item.model, picks:item.picks, ownership: item.ownership}} />
  </div>
))}
</Masonry>
          

        </div>
      </div>
    );
  // }
};

export default Gear;
