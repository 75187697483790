import React, { useEffect, useState, useContext } from "react";
import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import firebase from "../../firebase";
import "firebase/firestore";
import "firebase/storage";
import { firebaseAuth } from "../../providers/AuthProvider";
import { VerifiedUser, Pick, PickAdd, DeleteIcon } from "../../Constants";
import { BloodlinePost } from "./partials/BloodlinePost";
import { toast } from "react-toastify";
import { FilePond, registerPlugin } from "react-filepond";
import { create } from "../../lib/react-doka/lib/doka.esm.min";
import "../../lib/react-doka/lib/doka.min.css";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageFilter from "filepond-plugin-image-filter";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { StoryEntry } from "./partials/StoryEntry";
import { StoryEdit } from "./partials/StoryEdit";

const Edit = () => {
  const db = firebase.firestore();
  const { user } = useContext(firebaseAuth);
  const storage = firebase.storage().ref();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [owner, setOwner] = useState(null);
  const [cols, setCols] = useState({ media: "4", videos: "6" });
  const [feedItems, setFeedItems] = useState([]);
  const [userItems, setUserItems] = useState([]);
  const [editFeaturedMedia, setEditFeaturedMedia] = useState(false);
  const [editAllMedia, setEditAllMedia] = useState(false);
  const [editFeaturedVideo, setEditFeaturedVideo] = useState(false);
  const [editAllVideo, setEditAllVideo] = useState(false);
  const [files, setFiles] = useState([]);
  const [allMediaPond, setAllMediaPond] = useState(React.createRef());
  const [featuredMediaPond, setFeaturedMediaPond] = useState(React.createRef());
  const history = useHistory();
  const [copy, setCopy] = useState({ copyto: "", active: false });
  const [stories, setStories] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [editStoryItem, setEditStoryItem] = useState(null);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setEditStoryItem(null);
  };
  useEffect(() => {
    // if(!{ id }.id) {
    //   db.collection("items").add({ media:[],videos:[],picks:[],userId:user.uid}).then((doc) => {
    //     history.push("/edit/" + doc.id)
    //   initItem(doc.id);
    // });
    // } else {
      initStory();
    initItem({ id }.id);
    // }
  }, []);
  useEffect(() => {
    if (item && item.update) handleSubmit(item.close);
  }, [item]);
  const initStory = () => {
    firebase
          .firestore()
          .collection("stories")
          .where("itemId", "==", id)
          .orderBy("occured","desc")
          .get()
          .then((qs) => {
            if (qs.docs.length > 0) {
              let tmpStories = [];
                qs.docs.forEach((doc) => {
                  let tmpStory = doc.data();
                  tmpStory.id = doc.id;
                  tmpStories.push(tmpStory);
                });
                
              setStories(tmpStories);
            }
          });
  }
  const initItem = (id) => {
    firebase
      .firestore()
      .collection("items")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let tmpItem = doc.data();
          tmpItem.id = doc.id;
          if(tmpItem.bloodlineDefault)
            setActiveTab("2");
          if (!tmpItem.media) tmpItem.media = [];
          if (!tmpItem.videos) tmpItem.videos = [];
          setItem(tmpItem);

          setCols({
            media: tmpItem.media.length < 3 ? "4" : "4",
            videos: tmpItem.media.length < 3 ? "12" : "6",
          });
          let tmpFiles = [];
          tmpItem.media.map((image, i) => {
            tmpFiles.push({
              source: image.file,
              file: image.file,
              options: {
                type: "local",
              },
            });
          });

          setFiles(tmpFiles);
          firebase
            .firestore()
            .collection("users")
            .doc(doc.data().userId)
            .get()
            .then((u) => {
              if (u.exists) {
                setOwner(u.data());
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("item not found");
        }
      });
  };
  const deleteFile = (pathToFile, fileName) => {
    const ref = firebase.storage().ref(pathToFile);
    const childRef = ref.child(fileName);
    childRef.delete();
  };
  const deleteFolderContents = (path) => {
    const ref = firebase.storage().ref(path);
    ref
      .listAll()
      .then((dir) => {
        dir.items.forEach((fileRef) => {
          deleteFile(ref.fullPath, fileRef.name);
        });
        dir.prefixes.forEach((folderRef) => {
          deleteFolderContents(folderRef.fullPath);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteItem = (showMsg = true) => {
    if (window.confirm("Are you sure you want to delete the item?")) {
      var deleteItem = firebase.functions().httpsCallable("app/deleteItem");
      deleteItem({ docid: item.id })
        .then((result) => {
          deleteFolderContents("/" + user.uid + "/" + item.id);
          if (showMsg) toast.warning("Deleted item successfully.");
          history.push("/profile");
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            error.data ? error.data : "There was a problem updating your item."
          );
        });
    }
  };
  const copyItem = () => {
    if (
      window.confirm(
        "Are you sure you want to " +
          copy.method +
          " the item to " +
          copy.copyto +
          "?"
      )
    ) {
      db.collection("users")
        .where("handle", "==", copy.copyto)
        .limit(1)
        .get()
        .then((u) => {
          if (u.docs.length == 0) {
            toast.error("The user handle was not found, try again.");
            return;
          }
          let uid = u.docs[0].id;
          var copyItem = firebase.functions().httpsCallable("app/copyItem");
          copyItem({ uid: uid, copyItem: item })
            .then((result) => {
              if (copy.mode == "copy") {
                // copyFolderContents("/" + user.uid + "/" + item.id,);
                toast.success("Item copied successfully.");
              } else {
                // deleteItem(false);
                toast.success("Item transferred successfully.");
              }
            })
            .catch((error) => {
              console.log(error);
              toast.error(
                error.data
                  ? error.data
                  : "There was a problem updating your item."
              );
            });
        })
        .catch((err) => {
          console.log("uhoh");
          console.log(err);
        });
    }
  };
  const handleSubmit = (close = false) => {
    if (
      featuredMediaPond.current
        .getFiles()
        .filter((x) => x.status !== 5 && x.status !== 2).length == 0
    ) {
      var updateItem = firebase.functions().httpsCallable("app/updateItem");
      updateItem({ docid: item.id === "add" ? '':item.id, updateItem: item })
        .then((result) => {
          toast.success("Updated item successfully.");
          if (close) history.push("/profile");
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            err.message
              ? err.message
              : "There was a problem updating your item."
          );
        });
      setEditFeaturedMedia(false);
    } else {
      toast.warning("Files are still processing, please wait.");
    }
  };
  const handleAllSubmit = () => {
    if (
      allMediaPond.current
        .getFiles()
        .filter((x) => x.status !== 5 && x.status !== 2).length == 0
    )
      setEditAllMedia(false);
    else toast.warning("Files are still processing, please wait.");
  };
  const move = (array, from, to) => {
    if (to === from) return array;

    var target = array[from];
    var increment = to < from ? -1 : 1;

    for (var k = from; k != to; k += increment) {
      array[k] = array[k + increment];
    }
    array[to] = target;
    return array;
  };
  const server = {
    process: (fieldName, file, metadata, load, error, progress, abort) => {
      const task = storage
        .child("/" + user.uid + "/" + item.id + "/" + file.name)
        .put(file, {
          contentType: file.type,
        });
      task.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snap) => {
          progress(true, snap.bytesTransferred, snap.totalBytes);
        },
        (err) => {
          console.log(err);
        },
        () => {
          storage
            .child("/" + user.uid + "/" + item.id + "/" + file.name)
            .getDownloadURL()
            .then((url) => {
              load(url);
              setItem({
                ...item,
                featuredMedia: { url: url, file: file.name },
              });
            })
            .catch((err) => {
              abort();
            });
        }
      );
    },
    load: (source, load, error, progress, abort, headers) => {
      storage
        .child("/" + user.uid + "/" + item.id + "/" + source)
        .getDownloadURL()
        .then((url) => {
          let xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = function (event) {
            let blob = xhr.response;
            load(blob);
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch((err) => {
          console.log(err);
          abort();
        });
    },
  };
  const serverAllMedia = {
    process: (fieldName, file, metadata, load, error, progress, abort) => {
      const task = storage
        .child("/" + user.uid + "/" + item.id + "/" + file.name)
        .put(file, {
          contentType: file.type,
        });
      task.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snap) => {
          progress(true, snap.bytesTransferred, snap.totalBytes);
        },
        (err) => {
          console.log(err);
        },
        () => {
          storage
            .child("/" + user.uid + "/" + item.id + "/" + file.name)
            .getDownloadURL()
            .then((url) => {
              load(url);
              //update list
            })
            .catch((err) => {
              console.log(err);
              abort();
            });
        }
      );
    },
    remove: (source, load, error) => {
      load();
    },
    load: (source, load, error, progress, abort, headers) => {
      progress(true, 0, 1024);

      storage
        .child("/" + user.uid + "/" + item.id + "/" + source)
        .getDownloadURL()
        .then((url) => {
          let xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = function (event) {
            let blob = xhr.response;
            load(blob);
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch((err) => {
          console.log(err);
          abort();
        });
    },
  };
  const uploadPic = () => {
    storage
      .child("/" + user.uid + "/profile/hero_" + user.uid)
      .getDownloadURL()
      .then((url) => {
        // console.log(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const toggleEdit = () => {
    if (editFeaturedMedia) setEditFeaturedMedia(false);
    else setEditFeaturedMedia(true);
  };
  const toggleEditVideo = () => {
    if (editFeaturedVideo) setEditFeaturedVideo(false);
    else setEditFeaturedVideo(true);
  };
  const toggleEditAll = () => {
    if (editAllMedia) setEditAllMedia(false);
    else setEditAllMedia(true);
  };
  const toggleEditAllVideo = () => {
    if (editAllVideo) setEditAllVideo(false);
    else setEditAllVideo(true);
  };
  if (!user.isAuthenticated) return <Redirect to="/sign-in" />;
  if (item && item.userId != user.uid)
    return <Redirect to={"/gear/" + item.id} />;

  if (!item) {
    return <div></div>;
  } else {
    return (
      <div>
        <div className="py-3"></div>
        <div className="container h-100">
          <div className="row h-100 align-items-start">
            <div className="col-lg-4 col-md-12 col-12 align-self-start">
              <div className="pr-5">
              <div className={editStoryItem ? "d-none":""}>

                <div className="row align-items-center">
                  <div className="col-auto">
                    <div
                      className={item.picks.length > 0 ? "pick-view" : "d-none"}
                    >
                      <div className="align-items-center d-flex">
                        <div className="pr-2">
                          <i className="light md d-block">
                            <Pick />
                          </i>
                        </div>
                        <div>{item.picks.length}</div>
                      </div>
                    </div>
                  </div>

                  <div className="col"></div>
                </div>
                <div className="py-2"></div>
                <h4>
                  <Link to={"/" + (owner ? owner.handle : "")}>
                    {owner ? owner.handle : ""}{" "}
                    <VerifiedUser
                      verified={owner ? owner.verified : false}
                      size={"xs"}
                    />
                  </Link>
                </h4>

                <div className={"form-group"}>
                  <label className="form-label" htmlFor="brand">
                    Brand
                  </label>
                  <input
                    type="text"
                    id="brand"
                    name="brand"
                    value={item.brand || ""}
                    onChange={(e) => {
                      setItem({ ...item, brand: e.target.value });
                    }}
                    className="form-control"
                  />
                </div>
                <div className={"form-group"}>
                  <label className="form-label" htmlFor="model">
                    Model
                  </label>
                  <input
                    type="text"
                    id="model"
                    name="model"
                    value={item.model || ""}
                    onChange={(e) => {
                      setItem({ ...item, model: e.target.value });
                    }}
                    className="form-control"
                  />
                </div>
                <div className={"form-group"}>
                  <label className="form-label" htmlFor="year">
                    Year
                  </label>
                  <input
                    type="text"
                    id="year"
                    name="year"
                    value={item.year || ""}
                    onChange={(e) => {
                      setItem({ ...item, year: e.target.value });
                    }}
                    className="form-control"
                  />
                </div>
                <div className={"form-group"}>
                  <label className="form-label" htmlFor="nickname">
                    Nickname
                  </label>
                  <input
                    type="text"
                    id="nickname"
                    name="nickname"
                    value={item.nickname || ""}
                    onChange={(e) => {
                      setItem({ ...item, nickname: e.target.value });
                    }}
                    className="form-control"
                  />
                </div>
                <div className={"form-group"}>
                  <label className="form-label" htmlFor="itemType">
                    Item Type
                  </label>
                  <select
                    className="form-control"
                    id="itemType"
                    name="itemType"
                    onChange={(e) => {
                      setItem({ ...item, itemType: e.target.value });
                    }}
                    value={item.itemType || ""}
                  >
                    <option value=""></option>
                    <option value={"Guitar"}>Guitar</option>
                    <option value={"Amp"}>Amp</option>
                    <option value={"Pedal"}>Pedal</option>
                  </select>
                </div>
                <div className={"form-group"}>
                  <label className="form-label" htmlFor="serialNumber">
                    Serial Number
                  </label>
                  <input
                    type="text"
                    id="serialNumber"
                    name="serialNumber"
                    value={item.serialNumber || ""}
                    onChange={(e) => {
                      setItem({ ...item, serialNumber: e.target.value });
                    }}
                    className="form-control"
                  />
                </div>
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Featured Collection Item
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setItem({ ...item, featured: e.target.checked });
                      }}
                      id="featured"
                      name="featured"
                      defaultChecked={item.featured}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Currently own?
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setItem({ ...item, ownership: e.target.checked });
                      }}
                      id="ownership"
                      name="ownership"
                      defaultChecked={item.ownership}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Feature Bloodline?
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setItem({ ...item, bloodlineDefault: e.target.checked });
                      }}
                      id="bloodlineDefault"
                      name="bloodlineDefault"
                      defaultChecked={item.bloodlineDefault}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className={"form-group"}>
                  <label className="form-label" htmlFor="email">
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    onChange={(e) => {
                      setItem({ ...item, description: e.target.value });
                    }}
                    className="form-control"
                    defaultValue={item.description || ""}
                  />
                </div>
                <button
                  className={"btn btn-primary"}
                  onClick={() => {
                    setItem({
                      ...item,
                      close: true,
                      update: true,
                      close: true,
                    });
                  }}
                >
                  SAVE
                </button>
                <button
                  onClick={() => {
                    !item.featuredMedia
                      ? deleteItem()
                      : history.push("/profile");
                  }}
                  className={"ml-4 btn btn-default"}
                >
                  CANCEL
                </button>
                <button onClick={deleteItem} className={"ml-4 btn btn-default"}>
                  <i className="md">
                    <DeleteIcon />
                  </i>
                </button>
                </div>
                <div className="py-4"></div>
                <div className="copy-item">
                  <div className={copy.active ? "form-group" : "d-none"}>
                    <label className="form-label" htmlFor="brand">
                      Copy item to:
                    </label>
                    <input
                      type="text"
                      id="copyto"
                      name="copyto"
                      value={copy.copyto}
                      onChange={(e) => {
                        setCopy({ ...copy, copyto: e.target.value });
                      }}
                      className="form-control"
                    />
                  </div>
                  <div>
                    <button
                      className={!copy.active ? "d-none" : "btn btn-primary"}
                      onClick={copyItem}
                    >
                      COMPLETE
                    </button>
                    <button
                      className={
                        !copy.active ? "d-none" : "ml-4 btn btn-primary"
                      }
                      onClick={() => {
                        setCopy({ ...copy, active: false, method: "" });
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                  <div className="d-none">
                    <button
                      className={copy.active ? "d-none" : "btn btn-primary"}
                      onClick={() => {
                        setCopy({ ...copy, active: true, method: "copy" });
                      }}
                    >
                      COPY
                    </button>
                    <button
                      className={
                        copy.active ? "d-none" : "ml-4 btn btn-primary"
                      }
                      onClick={() => {
                        setCopy({ ...copy, active: true, method: "transfer" });
                      }}
                    >
                      TRANSFER
                    </button>
                    <div className="py-4"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-md-12 col-12">
              <Nav tabs className={editStoryItem ? "d-none":""}>
                <div className="col"></div>
                <NavItem className={activeTab === "1" ? "d-none" : ""}>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Gallery
                  </NavLink>
                </NavItem>
                <NavItem className={activeTab === "2" ? "d-none" : ""}>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Bloodline
                  </NavLink>
                </NavItem>
              </Nav>
              <div className="py-3"></div>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                <h3>Gallery</h3>
                  <div className={"row"}>
                    <div className="col px-0">
                      <img
                        onClick={toggleEdit}
                        className={
                          editFeaturedMedia || !item.featuredMedia
                            ? "d-none"
                            : "w-100 bl-media-featured"
                        }
                        src={item.featuredMedia ? item.featuredMedia.url : ""}
                        alt={item.brand + " " + item.model}
                      />
                      <div className="text-center">
                        <button
                          className={"btn btn-primary mb-4"}
                          onClick={toggleEdit}
                        >
                          EDIT FEATURED IMAGE
                        </button>
                      </div>
                      <div
                        className={editFeaturedMedia ? "pic-editor" : "d-none"}
                      >
                        <FilePond
                          ref={featuredMediaPond}
                          files={
                            item.featuredMedia
                              ? [
                                  {
                                    source: item.featuredMedia
                                      ? item.featuredMedia.file
                                      : "",
                                    file: item.featuredMedia
                                      ? item.featuredMedia.file
                                      : "",
                                    options: {
                                      type: "local",
                                    },
                                  },
                                ]
                              : []
                          }
                          allowReorder={false}
                          allowMultiple={false}
                          allowRevert={true}
                          maxFiles={1}
                          server={server}
                          onuploadfiles={() => {}}
                          imageCropAspectRatio="1:1"
                          imageResizeTargetWidth="1200"
                          imageTransformOutputQuality="60"
                          imageEditEditor={create({
                            cropAspectRatioOptions: [
                              {
                                label: "Portrait",
                                value: 1.7778,
                              },
                              {
                                label: "Square",
                                value: 1,
                              },
                              {
                                label: "Landscape",
                                value: 0.5625,
                              },
                            ],
                          })}
                          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        />
                        <div
                          className={"btn btn-primary pic-editor-done"}
                          onClick={handleSubmit}
                        >
                          DONE
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={item.featuredVideo ? "row" : "d-none"}>
                    <div className="px-0 col">
                      <div className="bl-video video-wrapper">
                        <iframe
                          className="w-100"
                          src={item.featuredVideo}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      !item.featuredVideo ? "text-center pb-4" : "d-none"
                    }
                  >
                    <hr />
                    <h3>No featured video selected.</h3>
                  </div>
                  <div
                    className={
                      editFeaturedVideo ? "text-center mb-4" : "d-none"
                    }
                  >
                    <div className={"form-group"}>
                      <label className="form-label" htmlFor="featuredVideo">
                        Featured Video
                      </label>
                      <input
                        type="text"
                        id="featuredVideo"
                        name="featuredVideo"
                        value={item.featuredVideo || ""}
                        onChange={(e) => {
                          setItem({ ...item, featuredVideo: e.target.value });
                        }}
                        className="form-control"
                      />
                    </div>
                    <button
                      className={"btn btn-primary"}
                      onClick={() => {
                        setItem({ ...item, close: false, update: true });
                        toggleEditVideo();
                      }}
                    >
                      SAVE FEATURED VIDEO
                    </button>
                    <button
                      className={"btn btn-primary ml-4"}
                      onClick={toggleEditVideo}
                    >
                      CANCEL
                    </button>
                  </div>
                  <div
                    className={
                      !editFeaturedVideo ? "text-center mb-4" : "d-none"
                    }
                  >
                    <button
                      className={"btn btn-primary"}
                      onClick={toggleEditVideo}
                    >
                      EDIT FEATURED VIDEO
                    </button>
                  </div>
                  <div className={editAllMedia ? "d-none" : "row"}>
                    {item.media.map((image, i) => (
                      <div
                        className={
                          "px-0 col-lg" + cols.media + " col-md-6 col-12"
                        }
                        key={i}
                      >
                        <img
                          onClick={toggleEditAll}
                          className="bl-media w-100"
                          src={image ? image.url : ""}
                          alt={item.brand + " " + item.model + " Image " + i}
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    className={
                      !editAllMedia && item.media && item.media.length === 0
                        ? "text-center pb-4"
                        : "d-none"
                    }
                  >
                    <hr />
                    <h3>No additional images selected.</h3>
                  </div>
                  <div className="text-center mb-4">
                    <button
                      className={"btn btn-primary"}
                      onClick={toggleEditAll}
                    >
                      EDIT IMAGES
                    </button>
                  </div>
                  <div className={editAllMedia ? "pic-editor" : "d-none"}>
                    <FilePond
                      ref={allMediaPond}
                      files={files}
                      allowReorder={true}
                      allowMultiple={true}
                      allowRevert={true}
                      server={serverAllMedia}
                      maxFiles={6}
                      maxParallelUploads={2}
                      imageTransformOutputQuality={60}
                      onremovefile={(error, file) => {
                        if (!error) {
                          storage
                            .child(
                              "/" +
                                user.uid +
                                "/" +
                                item.id +
                                "/" +
                                file.filename
                            )
                            .delete()
                            .then((result) => {
                              let found = 0;
                              let i = 0;
                              let tmpMedia = [];
                              console.log(file.filename);
                              item.media.forEach((z) => {
                                console.log(z.file);
                                if (file.filename != z.file) {
                                  tmpMedia.push(z);
                                } else {
                                  found++;
                                }
                                i++;
                              });
                              console.log(tmpMedia);
                              if (found > 0) {
                                setItem({
                                  ...item,
                                  close: false,
                                  update: true,
                                  media: tmpMedia,
                                });
                              }
                            })
                            .catch((err) => {
                              // console.log(item.media.length);
                              // console.log("/" + user.uid + "/" + item.id + "/" + file.filename);
                              console.log(err);
                            });

                          // item.media.forEach(z => {
                          //   console.log(z.filename);
                          //   if(z.filename == file.filename)
                          //     alert('pop');
                          // });
                        }
                      }}
                      // onprocessfileabort={file => {console.log('abort')}}
                      // onprocessfilerevert={file => {console.log('revert')}}
                      onupdatefiles={(files) => {
                        setFiles(files);
                      }}
                      onprocessfile={(error, file) => {
                        if (!error) {
                          storage
                            .child(
                              "/" +
                                user.uid +
                                "/" +
                                item.id +
                                "/" +
                                file.filename.replace("jpeg", "jpg")
                            )
                            .getDownloadURL()
                            .then((url) => {
                              let found = false;
                              item.media.map((img, i) => {
                                if (file.filename === img.file) found = true;
                              });
                              if (!found) {
                                setItem({
                                  ...item,
                                  update: true,
                                  close: false,
                                  media: [
                                    {
                                      file: file.filename.replace(
                                        "jpeg",
                                        "jpg"
                                      ),
                                      url: url,
                                    },
                                    ...item.media,
                                  ],
                                });
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }
                      }}
                      // onpreparefile={(file, output) => {console.log('prepare')}}
                      onreorderfiles={(files, origin, target) => {
                        // console.log(files);console.log(origin);console.log(target);
                        setItem({
                          ...item,
                          update: true,
                          close: false,
                          media: move(item.media, origin, target),
                        });
                      }}
                      imageCropAspectRatio="1:1"
                      imageResizeTargetWidth="1200"
                      imageTransformOutputQuality="60"
                      imageResizeUpscale={false}
                      imageEditEditor={create({
                        cropAspectRatioOptions: [
                          {
                            label: "Portrait",
                            value: 1.7778,
                          },
                          {
                            label: "Square",
                            value: 1,
                          },
                          {
                            label: "Landscape",
                            value: 0.5625,
                          },
                        ],
                      })}
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                    <div
                      className={"btn btn-primary pic-editor-done"}
                      onClick={handleAllSubmit}
                    >
                      DONE
                    </div>
                  </div>
                  <div
                    className={
                      item.videos.length > 0 || editAllVideo ? "row" : "d-none"
                    }
                  >
                    {item.videos.map((video, i) => (
                      <div
                        className={
                          "px-0 col-" + (item.videos.length > 1 ? "6" : "12")
                        }
                        key={i}
                      >
                        <div className="bl-video   video-wrapper">
                          <iframe
                            className="w-100"
                            src={video}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                        <div
                          className={
                            editAllVideo
                              ? "input-group px-1 pt-2 pb-3"
                              : "d-none"
                          }
                        >
                          <input
                            type="text"
                            id={"video-" + i}
                            name="video"
                            value={item.videos[i] || ""}
                            onChange={(e) => {
                              let videos = item.videos;
                              videos[i] = e.target.value;
                              setItem({ ...item, videos: videos });
                            }}
                            className="form-control"
                            aria-label="Youtube Embed Url"
                            aria-describedby="video-button{i}"
                          />
                          <div
                            className="input-group-append"
                            onClick={() => {
                              let videos = item.videos;
                              videos.splice(i, 1);
                              setItem({ ...item, videos: videos });
                            }}
                          >
                            <span
                              className="input-group-text"
                              id="video-button-{i}"
                            >
                              X
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div
                      className={
                        editAllVideo
                          ? "px-0 col-" + (item.videos.length > 1 ? "6" : "12")
                          : "d-none"
                      }
                    >
                      <button
                        className={"btn btn-primary"}
                        onClick={() => {
                          setItem({ ...item, videos: [...item.videos, ""] });
                        }}
                      >
                        + VIDEO
                      </button>
                    </div>
                  </div>
                  <div
                    className={
                      item.videos && item.videos.length === 0
                        ? "text-center pb-4"
                        : "d-none"
                    }
                  >
                    <hr />
                    <h3>No additional videos selected.</h3>
                  </div>
                  <div className={editAllVideo ? "text-center pb-4" : "d-none"}>
                    <button
                      className={"btn btn-primary"}
                      onClick={() => {
                        setItem({ ...item, update: true, close: false });
                        toggleEditAllVideo();
                      }}
                    >
                      SAVE VIDEOS
                    </button>
                    <button
                      className={"btn btn-primary ml-4"}
                      onClick={toggleEditAllVideo}
                    >
                      CANCEL
                    </button>
                  </div>
                  <div
                    className={!editAllVideo ? "text-center mb-4" : "d-none"}
                  >
                    <button
                      className={"btn btn-primary"}
                      onClick={toggleEditAllVideo}
                    >
                      EDIT VIDEOS
                    </button>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="row">
                    <div className="col-auto"><h3>Bloodline</h3></div>
                    <div className="col"></div>
                    <div className="col-auto"><button className={editStoryItem ? "d-none":"btn btn-primary"} onClick={() => { setEditStoryItem("add")}}>Add Story</button></div>

                  </div>
                  
                  <div className={editStoryItem ? "":"d-none"}>
                    <h5>Edit Story</h5>
                    <StoryEdit story={{media:[], itemId:item.id,id:'add'}} id={editStoryItem} onStoryClose={(c) => { initStory(); if (c) setEditStoryItem(null)}} />

                    </div>
                  <div>
                  <div className="py-3"></div>
                  {stories?.map((story, i) => (
<div key={i}>
  <div  className={editStoryItem ? "d-none":""}>
<StoryEntry story={story} i={i} last={i === stories.length - 1} edit={true} onEditStoryItem={(id) => { console.log(id);setEditStoryItem(id)}} />
                    </div>
<StoryEdit story={story} id={editStoryItem} onStoryClose={(c) => { initStory(); if (c) setEditStoryItem(null)}} />
</div>
    ))}          
    </div>
    <div className="py-5"></div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Edit;
