import React, { useEffect, useState, useContext } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import firebase from "../../firebase";
import "firebase/firestore";
import "firebase/storage";
import { firebaseAuth } from "../../providers/AuthProvider";
import { VerifiedUser,PreviouslyOwned,Pick,breakpointColumnsObj } from "../../Constants";
import Masonry from 'react-masonry-css'
import { SRLWrapper } from "simple-react-lightbox";
import { BloodlinePost } from "./partials/BloodlinePost";

const ProfileView = () => {
  const db = firebase.firestore();
  const [profilePic, setProfilePic] = useState({
    image: "/assets/images/profile-pic-placeholder.png",
    edit: false,
    default: true,
  });
  const [heroPic, setHeroPic] = useState({
    image: "/assets/images/hero-pic-placeholder.png",
    edit: false,
    default: true,
  });
  const [allItems, setAllItems] = useState([]);
  const { user } = useContext(firebaseAuth);
  const [userProfile, setUserProfile] = useState({});
  const storage = firebase.storage().ref();
  const [activeTab, setActiveTab] = useState("1");
  const [userProfileLoading, setUserProfileLoading] = useState(false);
  const [heroPicLoading, setHeroPicLoading] = useState(false);
  const [profilePicLoading, setProfilePicLoading] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pastItems, setpastItems] = useState([]);
  const [pickedItems, setPickedItems] = useState([]);
  const [feedItems, setFeedItems] = useState([]);

  const { id } = useParams();

  useEffect(() => {
      firebase
        .firestore()
        .collection("users")
        .where("handle", "==", { id }.id)
        .limit(1)
        .get()
        .then((u) => {
          console.log(u);

          if (u.docs.length > 0) {
            let tmpProfile = u.docs[0].data();
            tmpProfile.uid = u.docs[0].id;
            console.log(tmpProfile);
            setUserProfile(tmpProfile);
            setUserProfileLoading(true);
            uploadProfilePic(u.docs[0].id);
            uploadHeroPic(u.docs[0].id);
            firebase
              .firestore()
              .collection("items")
              .where("userId", "==", u.docs[0].id)
              .where("ownership", "==", true)
              .get()
              .then((qs) => {
                if (qs.docs.length > 0) {
                  let tmpItems = [];
                  qs.docs.forEach((doc) => {
                    let tmpItem = doc.data();
                    tmpItem.id = doc.id;
                    tmpItems.push(tmpItem);
                    setAllItems((allItems) => [...allItems, tmpItem]);
                  });
                  setCurrentItems(tmpItems);
                }
              });
            firebase
              .firestore()
              .collection("items")
              .where("userId", "==", u.docs[0].id)
              .where("ownership", "==", false)
              .get()
              .then((qs) => {
                if (qs.docs.length > 0) {
                  let tmpItems = [];
                  qs.docs.forEach((doc) => {
                    let tmpItem = doc.data();
                    tmpItem.id = doc.id;
                    tmpItems.push(tmpItem);
                    setAllItems((allItems) => [...allItems, tmpItem]);
                  });
                  setpastItems(tmpItems);
                }
              });

            firebase
              .firestore()
              .collection("items")
              .where("picks", "array-contains", u.docs[0].id)
              .limit(5)
              .get()
              .then((qs) => {
                if (qs.docs.length > 0) {
                  let tmpItems = [];
                  qs.docs.forEach((doc) => {
                    let tmpItem = doc.data();
                    tmpItem.id = doc.id;
                    tmpItems.push(tmpItem);
                  });
                  setPickedItems(tmpItems);
                }
              });
            firebase
              .firestore()
              .collection("items")
              .where("userId", "!=", u.docs[0].id)
              .get()
              .then((qs) => {
                if (qs.docs.length > 0) {
                  let tmpItems = [];
                  qs.docs.forEach((doc) => {
                    let tmpItem = doc.data();
                    tmpItem.id = doc.id;
                    tmpItems.push(tmpItem);
                  });
                  console.log('this many feed items:',tmpItems.length)
                  console.log(tmpItems.length);
                  setFeedItems(tmpItems);
                }
              });
          }
        })
        .catch((err) => {
          console.log("uhoh");
          console.log(err);
          setUserProfileLoading(true);
        });
  }, []);

  const uploadProfilePic = (uid) => {
    storage
      .child("/" + uid + "/profile/" + uid)
      .getDownloadURL()
      .then((url) => {
        setProfilePic({ image: url, edit: false });
        setProfilePicLoading(true);
      })
      .catch((err) => {
        console.log(err);
        setProfilePic({
          image: "/assets/images/profile-pic-placeholder.png",
          edit: false,
          default: true,
        });
        setProfilePicLoading(true);
      });
  };
  const uploadHeroPic = (uid) => {
    storage
      .child("/"+ uid + "/profile/hero_" + uid)
      .getDownloadURL()
      .then((url) => {
        setHeroPic({ image: url, edit: false });
        setHeroPicLoading(true);
      })
      .catch((err) => {
        console.log(err);
        setHeroPic({
          image: "/assets/images/hero-pic-placeholder.png",
          edit: false,
          default: true,
        });
        setHeroPicLoading(true);
      });
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <div className={heroPic.image.indexOf("/assets/") == -1 ? "pic-view":"d-none"}>
        <img
          className="hero-pic"
          src={heroPic.image}
          alt="Bloodline Profile Hero Picture"
        />
      </div>
      <div className="py-3"></div>
      <div className="container h-100">
        <div className="row h-100 align-items-center">
          <div className="col-lg-3 col-md-5 align-self-start">
            <div className="row">
              <div className="col-auto">
              <div className="pic-view profile-pic">
              <img
                className=""
                src={profilePic.image}
                alt="Bloodline Profile Picture"
              />
            </div>

              <h3>
              {userProfile.handle}{" "}
              <VerifiedUser verified={userProfile.verified} />
            </h3>

              <div className={user.uid == userProfile.userId ? "mb-3" :"d-none"}>
                <Link to="/profile" className="btn btn-primary btn-small">UPDATE PROFILE</Link>
              </div>

            </div>
            <div className="col">
            <p>{userProfile.location}</p>
            <p>{userProfile.bio}</p>
            </div>
            </div>
            
            <div className="d-none d-md-block">
            <h4>Current Collection</h4>
            {currentItems.map((item, i) => (
              <div className="" id={"current-list-" + i} key={i}>
                <Link to={"/gear/" + item.id} className="bl-post">
                  {item.brand} {item.model}{" "}
                </Link>
              </div>
            ))}
            <div className={"py-2"}></div>
            <h4>Past Collection</h4>
            {pastItems.map((item, i) => (
              <div className="" id={"past-list-" + i} key={i}>
                <Link to={"/gear/" + item.id} className="bl-post">
                  {item.brand} {item.model}{" "}
                </Link>
              </div>
            ))}
            <div className={"py-2"}></div>
            <h4>Recent Picks</h4>
            {pickedItems.map((item, i) => (
              <div className="" id={"picked-list-" + i} key={i}>
                <Link to={"/gear/" + item.id} className="bl-post">
                  {item.brand} {item.model}{" "}
                </Link>
              </div>
            ))}
            <div className={"py-2"}></div>
          </div>
          </div>
          <div className="col-lg-9 col-md-7">
            <div className={allItems.length === 0 ? "d-none" : "row"}>
              {allItems.map((item, i) => (
                <div
                  className={i === 0 ? "col-12" : "col-lg-4 col-md-6 col-12"}
                  id={"current-post-" + i}
                  key={i}
                >
                  <BloodlinePost item={item} />
                </div>
              ))}
            </div>
            <div className={allItems.length > 0 ? "d-none" : "row"}>
              <p>No items in the collection.</p>
            </div>
            <div className="py-5"></div>
          </div>
        </div>
        <hr />
        <div className="py-5"></div>
        <div className="pb-3">Toolbar</div>
        <Masonry
  breakpointCols={breakpointColumnsObj}
  className="my-masonry-grid"
  columnClassName="my-masonry-grid_column">
{feedItems.map((item,i) => (
   <div className="masonry-card" key={i}>
  <BloodlinePost item={{ id:item.id, featuredMedia:item.featuredMedia, brand:item.brand, model:item.model, picks:item.picks, ownership: item.ownership}} />
  </div>
))}
</Masonry>      </div>
    </div>
  );
};

export default ProfileView;
