import React from "react";
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
  return (
    <>
    <Container fluid={true}>
    <Row id="footer" className="">
      <Col></Col>
      <Col className="text-right py-3 copyright-txt">© 2020, BLOODLINE</Col>
    </Row>
    </Container>
    </>
  );
};

export default Footer;