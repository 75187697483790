import React from "react";
import { Route, Switch } from "react-router-dom";
import Contact from "./Contact";
import ForgotPassword from "./ForgotPassword";
import Home from "../main/Home";
import NavbarNav from "./Navbar";
import Footer from "./Footer";
import Profile from "./Profile";
import ProfileView from "./ProfileView";
import Gear from "./Gear";
import Edit from "./Edit";
import Add from "./Add";
import Collections from "./Collections";
import Collection from "./Collection";
import Picks from "./Picks";


import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import "../../assets/css/main.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainLayout = () => {
  return (
    <div>
    <div id="main-layout">
      <div className="header">
        <div className="text-center">
          <a href="https://www.fret12.com/">
            <img src="https://firebasestorage.googleapis.com/v0/b/f12-bloodlines.appspot.com/o/site%2FLogo_Bloodline.svg?alt=media&token=85e8037b-7b6a-4d94-aa7c-ab50063d4fee" />
          </a>
        </div>
      </div>
      <NavbarNav />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/" component={ProfileView} />
        <Route path="/contact" component={Contact} />
        <Route path="/profile" component={Profile} />
        <Route path="/sign-in" component={SignIn} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/edit/:id" component={Edit} />
        <Route exact path="/add" component={Add} />
        <Route exact path="/gear/:id" component={Gear} />
        <Route exact path="/collections" component={Collections} />
        <Route exact path="/collections/:id" component={Collection} />

        <Route exact path="/picks" component={Picks} />
        <Route exact path="/:id" component={ProfileView} />
      </Switch>
      </div>

      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      </div>
  );
};

export default MainLayout;
