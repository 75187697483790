// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';

// Add the Firebase products that you want to use
import 'firebase/firestore';
import "firebase/storage";
import 'firebase/auth';
import 'firebase/functions';

const prodConfig = {
  apiKey: "AIzaSyC2-VuP_ip2DhZhFowUPfrKG476lZ2NIMg",
  authDomain: "f12-bloodlines.firebaseapp.com",
  databaseURL: "https://f12-bloodlines.firebaseio.com",
  projectId: "f12-bloodlines",
  storageBucket: "f12-bloodlines.appspot.com",
  messagingSenderId: "941368115741",
  appId: "1:941368115741:web:25b3b04d79a3318210fcf4",
  measurementId: "G-D5B0Q6SP2T"
};
 
const devConfig = {
  apiKey: "AIzaSyC2-VuP_ip2DhZhFowUPfrKG476lZ2NIMg",
  authDomain: "f12-bloodlines.firebaseapp.com",
  databaseURL: "https://f12-bloodlines.firebaseio.com",
  projectId: "f12-bloodlines",
  storageBucket: "f12-bloodlines.appspot.com",
  messagingSenderId: "941368115741",
  appId: "1:941368115741:web:25b3b04d79a3318210fcf4",
  measurementId: "G-D5B0Q6SP2T"
};
 
const config =
  process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

// Initialize Firebase
firebase.initializeApp(config);
// firebase.analytics();
const db = firebase.firestore();
const storage = firebase.storage();

export {db,storage, firebase as default};

db.enablePersistence().catch(err => {
  if (err.code === 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    console.log('persistance failed');
  } else if (err.code === 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    console.log('persistance not available');
  }
});
if (window.location.hostname === 'localhost') {
  console.log("testing locally -- hitting local functions and firestore emulators");
  firebase.functions().useFunctionsEmulator('http://localhost:5000');
  // firebase.firestore().settings({
  //   host: 'localhost:8080',
  //   ssl: false
  // });
}