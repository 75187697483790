// import React from "react";
// import "./App.css";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Home from './Home';
// import Login from './Login';
// import MyProfile from './MyProfile';
// import SignUp from './SignUp';
// import Users from './Users';
// import Equipment from './Equipment';
// import EquipmentAdmin from './EquipmentAdmin';
// import { AuthProvider } from "./Auth";
// import PrivateRoute from "./PrivateRoute";

// function App() {

//   return (
//     <AuthProvider>
//       <Router>
//         <Switch>
//           <Route exact path="/users" component={Users} />
//           <PrivateRoute exact path="/myprofile" component={MyProfile} />
//           <Route exact path="/login" component={Login} />
//           <Route exact path="/equipment" component={Equipment} />
//           <Route exact path="/equipmentadmin" component={EquipmentAdmin} />
//           <Route exact path="/signup" component={SignUp} />
//           <Route exact path="/" component={Home} />
//         </Switch>
//       </Router>
//     </AuthProvider>
//   );
// }

// export default App;
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DashboardLayout from './components/manage/DashboardLayout';
import MainLayout from './components/main/MainLayout';
import AuthProvider from './providers/AuthProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/global.css';
import './assets/css/icons.css';
import SimpleReactLightbox from "simple-react-lightbox";



const App = () => {
  // const { user } = useContext(firebaseAuth);
  return (
    <BrowserRouter>
        <AuthProvider>
        <SimpleReactLightbox>

        <Switch>
          <Route path={["/manage","/manage/*"]}>
            <DashboardLayout />
          </Route>
          <Route path={["/*", "/"]}>
            <MainLayout />
          </Route>
        </Switch>
        </SimpleReactLightbox>

        </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
