import React, { useEffect, useState } from "react";
import { EditIcon, PreviouslyOwned, Pick } from "../../../Constants";
import { Link } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import "firebase/storage";
import moment from 'moment'

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import { SRLWrapper } from "simple-react-lightbox";
export const StoryEntry = React.memo((props) => {
  const [userHandle, setUserHandle] = useState("Unknown");
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const i = props.i;
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === props.story.media.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? props.story.media.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .doc(props.story.userId)
      .get()
      .then((u) => {
        if (u.exists) {
          setUserHandle(u.data().handle);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const StoryVideo = React.memo((props) => {
    if (props.video) {
      return (
        <div className="video-wrapper">
          <iframe
            className="w-100"
            src={props.video}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
    } else {
      return null;
    }
  });
  const StoryImages = React.memo((props) => {
    if (!props.video && props.media && props.media.length > 0) {
      return (
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={props.media}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {props.media.map((item, i) => (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={i}
              >
                <img src={item?.url} alt={"Story image #" + i} />
                {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
              </CarouselItem>
            ))}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Carousel>
      );
    } else {
      return null;
    }
  });

  return (
    <div className="row">
      <div
        className={
          i > 0 && i % 2 == 0 ? "col-md d-md-flex d-none story-left" : "d-none"
        }
      >
        <div className="w-100 h-100 d-flex align-items-center justify-content-end story-marker">
          <div className="story-marker-right"></div>
          <h4>
            {moment(new Date(props.story.occured).toUTCString()).format('MM/DD/YYYY')}{" "}
            by {userHandle}
            <span className={props.edit ? "" : "d-none"}>
            <i className="md" onClick={() => { props.onEditStoryItem(props.story.id)}}>
                <EditIcon />
              </i>
            </span>
          </h4>
        </div>
      </div>
      <div className={i == 0 ? "col-12" : "col-md col-12"} key={i}>
        <div
          className={
            i % 2 == 0
              ? i == 0
                ? "story-wrapper"
                : "story-wrapper pl-md-5"
              : i > 1
              ? "story-wrapper pr-md-5"
              : "story-wrapper pr-md-5"
          }
        >
          {/* <div className={i == 0 ? "d-none": i % 2 == 0 ? "story-marker-left":"story-marker-right"}></div> */}
          <div className={i == 1 ? "my-md-5" : "d-none"}></div>
          <div className="story-card">
          <SRLWrapper>

            <StoryVideo video={props.story.video} />
            <StoryImages video={props.story.video} media={props.story.media} />
            <div className="px-2 py-4">
              <h4>{props.story.name}</h4>
              <div>
              <h4 className={i === 0 ? "":"d-none"}>
            {new Date(props.story.occured).toLocaleDateString(
              "en-US"
            )}{" "}
            by {userHandle}
            <span className={props.edit ? "" : "d-none"}>
            <i className="md" onClick={() => { props.onEditStoryItem(props.story.id)}}>
                <EditIcon />
              </i>
            </span>
          </h4>
                {props.story.description}</div>
            </div>
            </SRLWrapper> 
          </div>
        </div>
        <div className={props.last ? "d-none":"py-4 py-md-0 story-mark-mobile"}></div>
      </div>
      <div
        className={
          i > 0 && i % 2 != 0 ? "col-md d-md-flex d-none story-right" : "d-none"
        }
      >
        <div className="w-100 h-100 d-flex align-items-center justify-content-start story-marker">
          <div className="story-marker-left"></div>
          <h4>
            {moment(new Date(props.story.occured).toUTCString()).format('MM/DD/YYYY')
            }{" "}
            by {userHandle}{" "}
            <span className={props.edit ? "" : "d-none"}>
              <i className="md" onClick={() => { props.onEditStoryItem(props.story.id)}}>
                <EditIcon />
              </i>
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
});
