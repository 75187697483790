import React, { useState, useEffect, useContext, useReducer } from "react";
import { Redirect } from "react-router-dom";
import { firebaseAuth } from "../../providers/AuthProvider";
import firebase from "firebase/app";
import { db } from "../../firebase";
import "firebase/functions";
import { DeleteIcon, EditIcon } from "../../Constants";
import "../../assets/css/groups.css";

const Groups = () => {
  const { user } = useContext(firebaseAuth);
  const reducer = (state, payload) => ({ ...state, ...payload });

  const [data, setData] = useReducer(reducer, { groups: [],
    isFetching: false,
    message: null, });

  const [editGroup, setEditGroup] = useState(null);

  const fetchGroups = async (groups) => {
    setData({
      groups: data.groups,
      isFetching: true,
      message: null,
    });
    let tmpGroups = [];
    db.collection("groups")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          tmpGroups.push({id:doc.id,data: doc.data()});
        });
        setData({
          groups: tmpGroups,
          isFetching: false,
          message: null,
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
        setData({
          groups: data.groups,
          isFetching: false,
          message: null,
        });
      });
  };
  useEffect(() => {
    setData({
      isFetching: true,
      message: null,
    });
    let tmpGroups2 = [];
    db.collection("groups")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          tmpGroups2.push({id:doc.id,data: doc.data()});
        });
        setData({
          groups: tmpGroups2,
          isFetching: false,
          message: null,
        });
     

      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
        setData({
          groups: data.groups,
          isFetching: false,
          message: null,
        });
      });
  }, []);
  if (!user.isAuthenticated) {
    return <Redirect to="/manage/sign-in" />;
  }
  function Fetching(props) {
    if (data.isFetching) {
      return <div className="alert alert-danger">Loading groups.</div>;
    } else {
      return null;
    }
  }

  function Message(props) {
    if (data.message) {
      return <div className="alert alert-danger">{data.message}</div>;
    } else {
      return null;
    }
  }

  function toggleEditGroup(group) {
    setData({
      message: null,
    });
    if (!editGroup) {
      setEditGroup(group);
    } else {
      setEditGroup(null);
    }
  }
  const deleteGroup = () => {
    //TODO: check if assigned to events or users
    db.collection('groups')
      .doc(editGroup.id)
      .delete();
      setEditGroup(null);
      fetchGroups();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(editGroup.id) {
      db.collection("groups").doc(editGroup.id).set(editGroup.data);
    }
    else {
      db.collection("groups").add(editGroup.data)
      .then(function(docRef) {
        console.log(docRef);  
      editGroup.data.name = docRef.id;  
      db.collection("groups").doc(docRef.id).set(editGroup.data);
      setEditGroup(null);
      fetchGroups();
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
  }
  };  
  const handleChange = (e) => {
    setEditGroup({
      ...editGroup,
      data: { ...editGroup.data, [e.target.name]: e.target.value }
    });
  };
  

  return (
    <div className="container">
      <div className={!editGroup ? "" : "d-none"}>
        <h1>Groups</h1>
        <div className="py-2"></div>
        <div className="text-right">
          <button onClick={() => toggleEditGroup({id:null,data:null})} className="btn btn-primary">Add Group</button>
        </div>
        <div className="py-2"></div>
        <Fetching />
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Group Name</th>
                <th className="d-none d-md-table-cell">Title</th>
                <th className="d-none d-md-table-cell">Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.groups.map((g, i) => (
                <tr
                  key={i}
                  className="editRow"
                  onClick={() => toggleEditGroup(g)}
                >
                  <td className="">{g.data.name}</td>
                  <td className="d-none d-md-table-cell">{g.data.title}</td>
                  <td className="d-none d-md-table-cell">{g.data.description}</td>
                  <td>
                    <i className="sm">
                      <EditIcon />
                    </i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={editGroup ? "" : "d-none"}>
        <div className="py-3"></div>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="name">
                Group Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={editGroup?.data?.name || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="title">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={editGroup?.data?.title || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="description">
                Description
              </label>
              <input
                type="text"
                id="description"
                name="description"
                value={editGroup?.data?.description || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="py-3"></div>
        <div className="row">
          <div className="col-auto">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Save
            </button>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-default"
              onClick={() => toggleEditGroup(null)}
            >
              Cancel
            </button>
          </div>
          <div className="col text-right">
            <button
              className="btn btn-link"
              onClick={() =>
                window.confirm("Are you sure to delete this record?")
                  ? deleteGroup()
                  : console.log("action cancelled by user.")
              }
            >
              <i className="sm">
                <DeleteIcon />
              </i>
            </button>
          </div>
        </div>
        <div className="py-3"></div>

        <Message />
      </div>
    </div>
  );
};

export default Groups;
