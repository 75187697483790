import React, { useEffect, useState, useRef } from "react";
import NavbarNav from "./Navbar";
import { Link } from "react-router-dom";

import firebase from "../../firebase";
import { Container } from "reactstrap";
import Masonry from "react-masonry-css";
import { BloodlinePost } from "./partials/BloodlinePost";
import { useWindowSize } from "@react-hook/window-size";
import { breakpointColumnsObj, VerifiedUser,RightArrow,LeftArrow} from "../../Constants";
import smoothscroll from "smoothscroll-polyfill";

const Users = () => {
  const [windowWidth, height] = useWindowSize();

  const [items, setItems] = React.useState([]);
  // const [itemImages, setItemImages] = React.useState([]);
  const [maxWidth, setMaxWidth] = useState(300);
  // const [maxColWidth, setMaxColWidth] = useState(360);
  const [featuredCollection, setFeaturedCollection] = useState({});
  const [featuredItemDetails, setFeaturedItemDetails] = useState([]);
  const [showFeaturedCollectionNav,setShowFeaturedCollectionNav] = useState(false);
  const featuredCollectionNav = useRef(null);
  smoothscroll.polyfill();

  useEffect(() => {
    const db = firebase.firestore();
    if (windowWidth < 576) {
      setMaxWidth((windowWidth - 50) / 2);
      // setMaxColWidth(maxWidth);
    } else if (windowWidth < 768) setMaxWidth(220);
    else if (windowWidth < 992) {
      setMaxWidth(330);
      // setMaxColWidth(337);
    } else if (windowWidth < 1200) setMaxWidth(330);
    db.collection("items").onSnapshot((snapshot) => {
      const itemsData = [];
      snapshot.forEach((doc) => itemsData.push({ ...doc.data(), id: doc.id }));
      setItems(itemsData);
    });

    db.collection("collections")
      .where("featuredCollection", "==", true)
      .limit(1)
      .get()
      .then((qs) => {
        if (qs.docs.length == 1) {
          let c = qs.docs[0].data();
          c.id = qs.docs[0].id;
          setFeaturedCollection(c);
          // {c.items.map((docid,i) => {
          c.items.forEach((docid, i) => {
            if(i >= 25)
              return;
            db.collection("items")
              .doc(docid)
              .get()
              .then((doc) => {
                let x = doc.data();
                if (x) {
                  x.id = doc.id;
                  db.collection("users")
                    .doc(x.userId)
                    .get()
                    .then((doc) => {
                      let y = doc.data();
                      if (y) {
                        y.id = doc.id;
                        x.ownerDetails = y;
                      }
                      setFeaturedItemDetails((featuredItemDetails) => [
                        ...featuredItemDetails,
                        x,
                      ]);
                    });
                } else {
                  console.log(doc);
                }
              });
          });

          // })}
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const scroll = (scrollOffset) => {
    if (scrollOffset === 0)
      featuredCollectionNav.current.scrollLeft = scrollOffset;
    else 
      featuredCollectionNav.current.scrollLeft += scrollOffset;
    if (featuredCollectionNav.current.scrollLeft == (featuredCollectionNav.current.scrollWidth - featuredCollectionNav.current.clientWidth))
      setShowFeaturedCollectionNav(true);
    else
      setShowFeaturedCollectionNav(false);

  };

  return (
    <div>
      <div className="container-fluid">
        <div className="pt-5 pb-4"></div>
        <div className="text-center">
          <h1 className="large text-uppercase">{featuredCollection.name}</h1>
          <h4>{featuredCollection.tagline}</h4>
        </div>

        <div className="py-4"></div>
        <div className="featured-collection-container">
          <div className="featured-collection" ref={featuredCollectionNav}>
            {featuredItemDetails.map((item, i) => (
              <Link to={"/gear/" + item.id} className="featured-collection-content" key={i}>
                <img
                  src={item.featuredMedia ? item.featuredMedia.url : ""}
                  alt={item.brand + " " + item.model}
                />
                <div className="text-center">
                  <h4>
                    {item.year} {item.model} {item.brand}
                  </h4>
                  <div className={item.ownerDetails ? "" : "d-none"}>
                    by {item.ownerDetails?.handle}{" "}
                    <VerifiedUser
                      verified={
                        item.ownerDetails ? item.ownerDetails.verified : false
                      }
                      size={"xs"}
                    />
                  </div>
                </div>
              </Link>
            ))}
            <div
              className={!showFeaturedCollectionNav ? "featured-collection-nav featured-collection-nav-right":"d-none"}
              onClick={() => scroll(100)}
            >
              <RightArrow />
            </div>
            <div
              className={!showFeaturedCollectionNav ? "d-none" : "featured-collection-nav featured-collection-nav-home"}
              onClick={() => scroll(0)}
            >
              <LeftArrow />
            </div>
          </div>
        </div>
      </div>

      <Container>
        <div className="py-5"></div>
        <div className="text-center">
          <div className="f-md">
            Think your collection has what it takes to join the Royal Bloodline?
            Show us what you got.
          </div>
          <Link className="btn-rounded btn btn-primary mt-3" to={"/profile"}>
            BECOME A CONTRIBUTOR
          </Link>
        </div>
        <div className="py-5"></div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {items.map((item, i) => (
            <div className="masonry-card" key={i}>
              <BloodlinePost
                item={{
                  id: item.id,
                  featuredMedia: item.featuredMedia,
                  brand: item.brand,
                  model: item.model,
                  picks: item.picks,
                  ownership: item.ownership,
                }}
              />
            </div>
          ))}
        </Masonry>
      </Container>
    </div>
  );
};

export default Users;
