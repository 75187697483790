import React, { useState, Fragment, useContext } from "react";
import { Route, Switch,Link } from "react-router-dom";
import { firebaseAuth } from "../../providers/AuthProvider";
import Users from "./Users";
import Settings from "./Settings";
import Groups from "./Groups";
import Invite from "./Invite";

import Profile from "./Profile";
import {
  UserIcon,
  GearIcon,
  ArrowDoubleRightIcon,
  ArrowDoubleDownIcon,
  LoginIcon,
  MenuIcon,
  UserGroupIcon,
  TabletIcon
} from "../../Constants";

// import PasswordForgetPage from './components/ForgotPassword';
// import AccountPage from './components/Account';
// import ManagePage from './components/Manage';
import "../../assets/css/dashboard.css";

const DashboardLayout = (props) => {
  const { user } = useContext(firebaseAuth);
  const [showTitles, setShowTitles] = useState("sidebar-titles-hide");
  const [showSidebar, setShowSidebar] = useState("sidebar-hide");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  function toggleSidebar() {
        if (showSidebar === "sidebar-hide") {
          setShowSidebar("");
          setShowTitles("sidebar-titles-show");
        } else {
                setShowSidebar("sidebar-hide");
                setShowTitles("sidebar-titles-hide");
        }
      }

  function toggleSidebarTitles() {
    if (showTitles === "sidebar-titles-show") {
      setShowTitles("sidebar-titles-hide");
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
      setShowTitles("sidebar-titles-show");
    }
  }
  function AuthenticatedSidebarLinks(props) {
    return (
      <Fragment>
        <ul className="nav flex-column">
          <li className="nav-item">
          <Link
              className="nav-link"
              to='/manage'
            >
              <i className="md">
                <UserIcon />
              </i>
              <span className={showTitles + " px-2"}>Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
          <Link
              className="nav-link"
              to='/manage'
            >
              <i className="md">
                <UserIcon />
              </i>
              <span className={showTitles + " px-2"}>Registrations</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to='/manage'
            >
              <i className="md">
                <UserIcon />
              </i>
              <span className={showTitles + " px-2"}>Add Registration</span>
            </Link>
          </li>
          <li className="nav-item">
          <Link
              className="nav-link"
              to='/manage'
            >              <i className="md">
                <UserIcon />
              </i>
              <span className={showTitles + " px-2"}>Galleries</span>
            </Link>
          </li>
        </ul>
        <h4
          className={
            showTitles +
            " d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
          }
        >
          <span className={showTitles + " px-2"}>Configuration</span>
        </h4>
        <ul className="nav flex-column mb-2">
          <li className="nav-item">
      <Link
              className="nav-link"
              to='/manage'
            >              <i className="md">
                <UserIcon />
              </i>
              <span className={showTitles + " px-2"}>Passcodes</span>
            </Link>
          </li>
          <li className="nav-item">
          <Link
              className="nav-link"
              to='/manage/settings'
            >              <i className="md">
                <GearIcon />
              </i>
              <span className={showTitles + " px-2"}>Settings</span>
            </Link>
          </li>
          <li className="nav-item">
          <Link
              className="nav-link"
              to='/manage/groups'
            >              <i className="md">
                <UserGroupIcon />
              </i>
              <span className={showTitles + " px-2"}>Groups</span>
            </Link>
          </li>
          <li className="nav-item">
          <Link
              className="nav-link"
              to='/manage/users'
            >              <i className="md">
                <UserIcon />
              </i>
              <span className={showTitles + " px-2"}>Users</span>
            </Link>
          </li>

        </ul>
      </Fragment>
    );
  }
  function NonAuthenticatedSidebarLinks(props) {
    return (
      <Fragment>
        <ul className="nav flex-column">
          <li className="nav-item">
                  
            <Link className="nav-link" to="/manage/sign-in">
            <i className="md">
                <LoginIcon />
              </i>
              <span className={showTitles + " px-2"}>Login</span>
            </Link>
          </li>
        </ul>
      </Fragment>
    );
  }

  function ShowSidebarLinks(props) {
    if (user.isAuthenticated) {
      return <AuthenticatedSidebarLinks />;
    }
    return <NonAuthenticatedSidebarLinks />;
  }

  function AuthenticatedLinks(props) {
        return (
          <Fragment>
            <li className="nav-item">
              <Link className="nav-link" to="/manage/profile">
                <i className="md light">
                  <UserIcon />
                </i>
              </Link>
            </li>
          </Fragment>
        );
      }
      function NonAuthenticatedLinks(props) {
        return (
          <li className="nav-item text-nowrap">
            <Link className="nav-link" to="/manage/sign-in">
              Login
            </Link>
          </li>
        );
      }
    
      function ShowLinks(props) {
        if (user.isAuthenticated) {
          return <AuthenticatedLinks />;
        }
        return <NonAuthenticatedLinks />;
      }
  return (
    <div id="dashboard-layout">
            <nav
        id="nav"
        className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow"
      >
                   <div
              className="d-md-none col-auto px-2"
              onClick={toggleSidebar}
            >
              <i className="sm light">
                <MenuIcon />
              </i>
            </div>
        <Link className="navbar-brand col-auto mr-0" to="/manage">
          F12<span>bloodlines</span>
        </Link>
        <div className="col"></div>
        <ul className="navbar-nav px-3">
          <ShowLinks />
        </ul>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <nav className={"col-auto px-0 sidebar " + showSidebar} id="app-drawer">
            <div
              className="d-xl-none d-md-block d-none pl-md-3 px-2 mt-2"
              onClick={toggleSidebarTitles}
            >
              <i className={sidebarOpen ? "d-none md" : "md"}>
                <ArrowDoubleRightIcon />
              </i>
              <i className={sidebarOpen ? "md" : "d-none md"}>
                <ArrowDoubleDownIcon />
              </i>
              <span className={showTitles + " px-2"}></span>
            </div>

            <div className="sidebar-sticky">
              <ShowSidebarLinks />
            </div>
          </nav>
          <main id="main" role="main" className="col ml-sm-auto pr-4">
            <Switch>
              <Route path="/manage/groups" component={Groups} />
              <Route path="/manage/profile" component={Profile} />

              <Route path="/manage/users" component={Users} />
              <Route path="/manage/invite" component={Invite} />
              <Route path="/manage" component={Profile} />
              {/*<Route path='/forgot-password' component={ForgotPassword} />
                                                        <Route path='/account' component={Account} />
                                                        <Route path='/manage' component={Manage} /> */}
            </Switch>
          </main>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
