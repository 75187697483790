import React, { useEffect,useState,useContext} from "react";
import { DeleteIcon, Pick } from "../../../Constants";
import { Link } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import "firebase/storage";
import { firebaseAuth } from "../../../providers/AuthProvider";
import { toast } from "react-toastify";
import { FilePond, registerPlugin } from "react-filepond";
import { create } from "../../../lib/react-doka/lib/doka.esm.min";
import "../../../lib/react-doka/lib/doka.min.css";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageFilter from "filepond-plugin-image-filter";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
export const StoryEdit = React.memo((props) => {
  const [item, setItem] = useState({});
  const [editFeaturedVideo, setEditFeaturedVideo] = useState(false);
  const [editAllMedia, setEditAllMedia] = useState(false);
  const [allMediaPond, setAllMediaPond] = useState(React.createRef());
  const { user } = useContext(firebaseAuth);
  const storage = firebase.storage().ref();
  const [cols, setCols] = useState({ media: "4", videos: "6" });
  const [files, setFiles] = useState([]);
  const [showStoryEdit, setShowStoryEdit] = useState(false);
  useEffect(() => {

          let tmpFiles = [];
          console.log(props.story)
  
  if(props.story) {
    
    if(props.story.media) {
    props.story.media.map((image, i) => {
            tmpFiles.push({
              source: image.file,
              file: image.file,
              options: {
                type: "local",
              },
            });
          });
          setFiles(tmpFiles);
        } else {
          props.story.media = [];
      } 
      setItem(props.story);

      }
    }, []);
    useEffect(() => {
    if(item.id === props.id) 
  setShowStoryEdit(true);
  else 
  setShowStoryEdit(false);      
        }, [props.id]);
        useEffect(() => {
          console.log('reset story');
          let tmpFiles = [];

          if(props.story) {
    
            if(props.story.media) {
            props.story.media.map((image, i) => {
                    tmpFiles.push({
                      source: image.file,
                      file: image.file,
                      options: {
                        type: "local",
                      },
                    });
                  });
                  setFiles(tmpFiles);
                } else {
                  props.story.media = [];
              } 
              setItem(props.story);
        
              }
              }, [props.story]);
    useEffect(() => {
      if (item && item.update) {
        handleSubmit(item.close);
      } 
    }, [item]);
    
    
    const handleSubmit = (close = false) => {
      if (
        allMediaPond.current
          .getFiles()
          .filter((x) => x.status !== 5 && x.status !== 2).length == 0
      ) {
        var updateItem = firebase.functions().httpsCallable("app/updateStory");
        updateItem({ docid: item.id, updateItem: item })
          .then((result) => {
            if(!item.id || item.id == "add")
              setItem({ ...item, id: result.data,update:false });
            toast.success("Updated story successfully.");
            if (close) { 
              // allMediaPond.current.removeFiles();
              // setFiles([]);
              props.onStoryClose(true); }
          })
          .catch((err) => {
            console.log(err);
            toast.error(
              err.message
                ? err.message
                : "There was a problem updating your item."
            );
          });
        // setEditAllMedia(false);
      } 
      // else {
      //   toast.warning("Files are still processing, please wait.");
      // }
    };
    const toggleEditVideo = () => {
      if (editFeaturedVideo) setEditFeaturedVideo(false);
      else setEditFeaturedVideo(true);
    };
    const toggleEditAll = () => {
      if(!item.id || item.id == "add") {
        setItem({ ...item, update: true });
      }
      if (editAllMedia) setEditAllMedia(false);
      else setEditAllMedia(true);
    };
    const move = (array, from, to) => {
      if (to === from) return array;
  
      var target = array[from];
      var increment = to < from ? -1 : 1;
  
      for (var k = from; k != to; k += increment) {
        array[k] = array[k + increment];
      }
      array[to] = target;
      return array;
    };
    const handleAllSubmit = () => {
      if (
        allMediaPond.current
          .getFiles()
          .filter((x) => x.status !== 5 && x.status !== 2).length == 0
      )
        setEditAllMedia(false);
      else toast.warning("Files are still processing, please wait.");
    };
    const serverAllMedia = {
      process: (fieldName, file, metadata, load, error, progress, abort) => {
        const task = storage
          .child("/" + user.uid + "/" + item.itemId + "/" + item.id + "/" + file.name)
          .put(file, {
            contentType: file.type,
          });
        task.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snap) => {
            progress(true, snap.bytesTransferred, snap.totalBytes);
          },
          (err) => {
            console.log(err);
          },
          () => {
            storage
              .child("/" + user.uid + "/" + item.itemId + "/" + item.id + "/" + file.name)
              .getDownloadURL()
              .then((url) => {
                load(url);
                //update list
              })
              .catch((err) => {
                console.log(err);
                abort();
              });
          }
        );
      },
      remove: (source, load, error) => {
        load();
      },
      load: (source, load, error, progress, abort, headers) => {
        progress(true, 0, 1024);
  
        storage
          .child("/" + user.uid + "/" + item.itemId + "/" + item.id + "/" + source)
          .getDownloadURL()
          .then((url) => {
            let xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onload = function (event) {
              let blob = xhr.response;
              load(blob);
            };
            xhr.open("GET", url);
            xhr.send();
          })
          .catch((err) => {
            console.log(err);
            abort();
          });
      },
    };
    const deleteFile = (pathToFile, fileName) => {
      const ref = firebase.storage().ref(pathToFile);
      const childRef = ref.child(fileName);
      childRef.delete();
    };
    const deleteFolderContents = (path) => {
      const ref = firebase.storage().ref(path);
      ref
        .listAll()
        .then((dir) => {
          dir.items.forEach((fileRef) => {
            deleteFile(ref.fullPath, fileRef.name);
          });
          dir.prefixes.forEach((folderRef) => {
            deleteFolderContents(folderRef.fullPath);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    const deleteItem = (showMsg = true) => {
      if (window.confirm("Are you sure you want to delete the item?")) {
        var deleteStory = firebase.functions().httpsCallable("app/deleteStory");
        deleteStory({ docid: item.id })
          .then((result) => {
            deleteFolderContents("/" + user.uid + "/" + item.itemId + "/" + item.id);
            if (showMsg) toast.warning("Deleted story successfully.");
            // allMediaPond.current.removeFiles()
            // setFiles([]);
            props.onStoryClose(true);
          })
          .catch((error) => {
            console.log(error);
            toast.error(
              error.data ? error.data : "There was a problem removing your story."
            );
          });
      }
    };
  return (

<div className={showStoryEdit ? "":"d-none"}>
 
  <div className="py-3"></div>
<div className={"form-group"}>
                  <label className="form-label" htmlFor="name">
                    Story Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={item.name || ""}
                    onChange={(e) => {
                      setItem({ ...item, name: e.target.value,update: false
                      });
                    }}
                    className="form-control"
                  />
                </div>
                <div className={"form-group"}>
                      <label className="form-label" htmlFor="occured">
                        Date {item.occured}
                      </label>
                      <input
                        type="date"
                        id="occured"
                        name="occured"
                        value={item.occured || ""}
                        onChange={(e) => {
                          setItem({ ...item, occured: e.target.value,update: false });
                        }}
                        className="form-control"
                      />
                    </div>
                    <div className={"form-group"}>
                  <label className="form-label" htmlFor="type">
                    Type
                  </label>
                  <select
                    className="form-control"
                    id="type"
                    name="type"
                    onChange={(e) => {
                      setItem({ ...item, type: e.target.value,update: false });
                    }}
                    value={item.type || ""}
                  >
                    <option value={"Journal"}>Journal</option>
                    <option value={"Transfer"}>Transfer</option>
                    <option value={"Sold"}>Sold</option>
                    <option value={"Purchase"}>Purchase</option>
                    <option value={"Damage"}>Damage</option>
                    <option value={"Lost"}>Lost</option>
                  </select>
                </div>
                <div className={"form-group"}>
                  <label className="form-label" htmlFor="email">
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    onChange={(e) => {
                      setItem({ ...item, description: e.target.value,update: false });
                    }}
                    className="form-control"
                    defaultValue={item.description || ""}
                  />
                </div>
                <div className={item.video ? "row" : "d-none"}>
                    <div className="px-0 col">
                      <div className="bl-video video-wrapper">
                        <iframe
                          className="w-100"
                          src={item.video}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                <div
                    className={
                      !item.video ? "text-center pb-4" : "d-none"
                    }
                  >
                    <hr />
                    <h3>No featured video selected.</h3>
                  </div>
                  <div
                    className={
                      editFeaturedVideo ? "text-center mb-4" : "d-none"
                    }
                  >
                    <div className={"form-group"}>
                      <label className="form-label" htmlFor="video">
                        Featured Video
                      </label>
                      <input
                        type="text"
                        id="video"
                        name="video"
                        value={item.video || ""}
                        onChange={(e) => {
                          setItem({ ...item, video: e.target.value ,update: false});
                        }}
                        className="form-control"
                      />
                    </div>
                    <button
                      className={"btn btn-primary"}
                      onClick={() => {
                        setItem({ ...item, close: false, update: false });
                        toggleEditVideo();
                      }}
                    >
                      SAVE FEATURED VIDEO
                    </button>
                    <button
                      className={"btn btn-primary ml-4"}
                      onClick={toggleEditVideo}
                    >
                      CANCEL
                    </button>
      
                  </div>
                  <div
                    className={
                      !editFeaturedVideo ? "text-center mb-4" : "d-none"
                    }
                  >
                    <button
                      className={"btn btn-primary"}
                      onClick={toggleEditVideo}
                    >
                      EDIT FEATURED VIDEO
                    </button>
                  </div>
                  <div className={editAllMedia ? "d-none" : "row"}>
                    {item.media?.map((image, i) => (
                      <div
                        className={
                          "px-0 col-lg" + cols.media + " col-md-6 col-12"
                        }
                        key={i}
                      >
                        <img
                          onClick={toggleEditAll}
                          className="bl-media w-100"
                          src={image ? image.url : ""}
                          alt={item.brand + " " + item.model + " Image " + i}
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    className={
                      !editAllMedia && item.media && item.media.length === 0
                        ? "text-center pb-4"
                        : "d-none"
                    }
                  >
                    <hr />
                    <h3>No additional images selected.</h3>
                  </div>
                  <div className="text-center mb-4">
                    <button
                      className={"btn btn-primary"}
                      onClick={toggleEditAll}
                    >
                      EDIT IMAGES
                    </button>
                  </div>
                  <div className={editAllMedia ? "pic-editor" : "d-none"}>
                    <FilePond key={item.id}
                      ref={allMediaPond}
                      files={files}
                      allowReorder={true}
                      allowMultiple={true}
                      allowRevert={true}
                      server={serverAllMedia}
                      maxFiles={6}
                      maxParallelUploads={2}
                      imageTransformOutputQuality={60}
                      onremovefile={(error, file) => {
                        console.log('why');
                        if (!error) {
                          storage
                            .child(
                              "/" +
                                user.uid +
                                "/" +
                                item.itemId +
                                "/" +
                                item.id +
                                "/" +
                                file.filename
                            )
                            .delete()
                            .then((result) => {
                              let found = 0;
                              let i = 0;
                              let tmpMedia = [];
                              if(item.media){
                              item.media.forEach((z) => {
                                if (file.filename != z.file) {
                                  tmpMedia.push(z);
                                } else {
                                  found++;
                                }
                                i++;
                              });
                            }
                              if (found > 0) {
                                setItem({
                                  ...item,
                                  close: false,
                                  update: true,
                                  media: tmpMedia,
                                });
                              }
                            })
                            .catch((err) => {
                              // console.log(item.media.length);
                              // console.log("/" + user.uid + "/" + item.id + "/" + file.filename);
                              console.log(err);
                            });

                          // item.media.forEach(z => {
                          //   console.log(z.filename);
                          //   if(z.filename == file.filename)
                          //     alert('pop');
                          // });
                        }
                      }}
                      // onprocessfileabort={file => {console.log('abort')}}
                      // onprocessfilerevert={file => {console.log('revert')}}
                      onupdatefiles={(files) => {
                        setFiles(files);
                      }}
                      onprocessfile={(error, file) => {
                        if (!error) {
                          storage
                            .child(
                              "/" +
                                user.uid +
                                "/" +
                                item.itemId +
                                "/" +
                                item.id +
                                "/" +
                                file.filename.replace("jpeg", "jpg")
                            )
                            .getDownloadURL()
                            .then((url) => {
                              let found = false;
                              if(item.media) {
                              item.media.map((img, i) => {
                                if (file.filename === img.file) found = true;
                              });
                              }
                              if (!found) {
                                setItem({
                                  ...item,
                                  update: true,
                                  close: false,
                                  media: [
                                    {
                                      file: file.filename.replace(
                                        "jpeg",
                                        "jpg"
                                      ),
                                      url: url,
                                    },
                                    ...item.media,
                                  ],
                                });
                              }
                              
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }
                      }}
                      // onpreparefile={(file, output) => {console.log('prepare')}}
                      onreorderfiles={(files, origin, target) => {
                        // console.log(files);console.log(origin);console.log(target);
                        let tmpMedia = move(item.media, origin, target);
                        console.log(tmpMedia);
                        setItem({
                          ...item,
                          update: true,
                          close: false,
                          media: tmpMedia
                        });
                      }}
                      imageCropAspectRatio="1:1"
                      imageResizeTargetWidth="1200"
                      imageTransformOutputQuality="60"
                      imageResizeUpscale={false}
                      imageEditEditor={create({
                        cropAspectRatioOptions: [
                          {
                            label: "Portrait",
                            value: 1.7778,
                          },
                          {
                            label: "Square",
                            value: 1,
                          },
                          {
                            label: "Landscape",
                            value: 0.5625,
                          },
                        ],
                      })}
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                    <div
                      className={"btn btn-primary pic-editor-done"}
                      onClick={handleAllSubmit}
                    >
                      DONE
                    </div>
                  </div>
                
<div>
<button
                  className={"btn btn-primary"}
                  onClick={() => {
                    setItem({
                      ...item,
                      close: true,
                      update: true
                    });
                  }}
                >
                  SAVE
                </button>
                 <button
                  onClick={() => {
                    // allMediaPond.current.removeFiles();
                    // setFiles([]);
                    console.log(allMediaPond);
             // FilePond.destroy(allMediaPond);
                    // allMediaPond.destroy();
                    props.onStoryClose(true)}}
                  className={"ml-4 btn btn-default"}
                >
                  CANCEL
                </button>
                <button onClick={deleteItem} className={props.id === "add" ? "d-none":"ml-4 btn btn-default"}>
                  <i className="md">
                    <DeleteIcon />
                  </i>
                </button>
</div>
</div>
  );
});
