import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import firebase from "../../firebase";
import "firebase/firestore";
import "firebase/storage";
import { firebaseAuth } from "../../providers/AuthProvider";
import Masonry from "react-masonry-css";
import { BloodlinePost } from "./partials/BloodlinePost";
import { breakpointColumnsObj} from "../../Constants";

const Collection = () => {
  const db = firebase.firestore();
  const { user } = useContext(firebaseAuth);
  const storage = firebase.storage().ref();
  const { id } = useParams();
  const [item, setItem] = useState({});
  const [cols, setCols] = useState({ media: "4", videos: "6" });
  const [collectionItems, setCollectionItems] = useState([]);
  const [feedItems, setFeedItems] = useState([]);
  const history = useHistory();
  useEffect(() => {
    initItem();
  }, []);

  const initItem = () => {
    firebase
      .firestore()
      .collection("collections")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let tmpItem = doc.data();
          tmpItem.id = doc.id;
          if (!tmpItem.items) tmpItem.media = [];
          setItem(tmpItem);
          {tmpItem.items.map((item, i) => {
            firebase
            .firestore()
            .collection("items")
            .doc(item)
            .get()
            .then((idoc) => {
              if (idoc.exists) {
                let tmp = idoc.data();
                tmp.id = idoc.id;
                setFeedItems((feedItems) => [
                  ...feedItems,
                  tmp,
                ]);              }
            });
          })};
        }
      });
  };

  return (
    <div>
      <div className="py-5"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-12">
            <img className="w-100 pr-md-5 pl-md-0 pr-2 pl-2 pb-5" src={item.featuredImage?.url} alt={item.name} />
          </div>
          <div className="col-md-8 col-12">
            <h1>{item.name}</h1>
            <div>{item.tagline}</div>
            <div className="py-2"></div>
            <div>{item.description}</div>
          </div>
        </div>
        <div className="py-4"></div>
        <div className="text-center"><h2>The Collection</h2></div>
        <div className="py-4"></div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {feedItems.map((item, i) => (
            <div className="masonry-card" key={i}>
              <BloodlinePost
                item={{
                  id: item.id,
                  featuredMedia: item.featuredMedia,
                  brand: item.brand,
                  model: item.model,
                  picks: item.picks,
                  ownership: item.ownership,
                }}
              />
            </div>
          ))}
        </Masonry>
      </div>

    </div>
  );
  // }
};

export default Collection;
