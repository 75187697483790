import React, { useContext, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { firebaseAuth } from "../../providers/AuthProvider";
import { db } from "../../firebase";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {Form,FormGroup,Button,Alert,Label,Input} from 'reactstrap';
import { AuthUrl} from '../../Constants';
import { toast } from "react-toastify";

const ForgotPassword = (props) => {
  const {
    inputs,
    setInputs,
    user,
    handleSignout
  } = useContext(firebaseAuth);
  const [redirect, setRedirect] = useState(false);
  

  useEffect(() => {
    if (user.isAuthenticated) 
      handleSignout();
  }, [user]);
  useEffect(() => {
    setInputs({ email: "", finished:false});
  }, []);

  if (redirect) return <Redirect to="/manage" />;

  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: inputs.email })
  };
  fetch(AuthUrl + 'forgot-password', requestOptions)
      .then(async response => {
        const data = await response.json();      
        if (!response.ok) {
            const error = (data && data.message) || response.status;
            toast.error(data);
            
            return Promise.reject(error);
        }
        toast.success(data.message);
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputs((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <div className="container account-page">
          <div className="container account-page">
      <div className="py-3"></div>
      <h1>Forgot Password</h1>
      <div className="py-2"></div>
      {inputs.pw_update ? <Alert variant="success">Password set successfully.  Please login below.</Alert>:""}
      <div className="py-3"></div>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Email Address</Label>
          <Input type="email" id="email" onChange={handleChange} />
        </FormGroup>

        <div className="py-1"></div>
        <Button className="btn btn-primary" type="submit">Submit</Button>
        <div className="py-1"></div>
        <div className="row">
          <div className="col-auto">
            <Link className="btn-link" to="/sign-in">ACCESS YOUR ACCOUNT?</Link>

          </div>
        </div>
        <div className="py-3"></div>
      </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
