import React from 'react';
import { PreviouslyOwned,Pick } from "../../../Constants";
import { Link } from "react-router-dom";

export const BloodlinePost = (props) => {
  
return (<div className = "bl-post">
<Link to={"/" + (props.edit ? "edit" : "gear") + "/" + props.item.id} className="bl-link">
  <img
    src={props.item.featuredMedia ? props.item.featuredMedia.url : ""}
    alt={props.item.brand + " " + props.item.model}
  />
  <div className={ props.item.picks && props.item.picks.length >= 0 ? "pick-view":"d-none"}><div className="align-items-center d-flex"><div className="pr-2"><i className="light xs d-block"><Pick /></i></div><div>{props.item.picks?.length}</div></div></div>
  <div className="pt-2 pb-2 text-center align-items-center justify-content-center bl-post-details">
    <div className="pr-2">{props.item.brand + " " + props.item.model}</div> <PreviouslyOwned ownership={props.item.ownership} />
  </div>
</Link>
</div>);
}