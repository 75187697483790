import React, { useEffect, useState, useContext } from "react";
import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import firebase from "../../firebase";
import "firebase/firestore";
import "firebase/storage";
import { firebaseAuth } from "../../providers/AuthProvider";
import {
  VerifiedUser,
  Pick,
  PickAdd,
  breakpointColumnsObj,
} from "../../Constants";
import Masonry from "react-masonry-css";

import { SRLWrapper } from "simple-react-lightbox";
import { BloodlinePost } from "./partials/BloodlinePost";
import { toast } from "react-toastify";
import { useWindowScroll } from "@react-hook/window-scroll";
import { useWindowSize } from "@react-hook/window-size";

const Collections = () => {
  const db = firebase.firestore();
  const { user } = useContext(firebaseAuth);
  const storage = firebase.storage().ref();
  const { id } = useParams();
  const [item, setItem] = useState({});
  const [owner, setOwner] = useState(null);
  const [cols, setCols] = useState({ media: "4", videos: "6" });
  const [feedItems, setFeedItems] = useState([]);
  const history = useHistory();
  useEffect(() => {
    firebase
      .firestore()
      .collection("collections")
      .orderBy("featuredCollection","desc")
      .get()
      .then((qs) => {
        if (qs.docs.length > 0) {
          let tmpItems = [];
          qs.docs.forEach((doc) => {
            let tmpItem = doc.data();
            tmpItem.id = doc.id;
            tmpItems.push(tmpItem);
          });
          setFeedItems(tmpItems);
        }
      });
  }, []);

  const MasonryCard = ({
    index,
    data: { id, model, brand, featuredMedia, picks, ownership },
    width,
  }) => (
    <div className="masonry-card">
      <BloodlinePost
        item={{
          id: id,
          featuredMedia: featuredMedia,
          brand: brand,
          model: model,
          picks: picks,
          ownership: ownership,
        }}
      />
    </div>
  );
  // if (!item) {
  //   return <div>Item not found.</div>;
  // } else {
  return (
    <div>
      <div className="py-5"></div>
      <div className="container">
        <h1 className="text-center">Collections</h1>
        <div className="py-4"></div>
        <div className="row">
          {feedItems.map((item, i) => (
            <div className="col-lg-4 col-md-6 col-12" key={i}>
              <Link to={"/collections/" + item.id}>
                {" "}
                <div className="collection-card pb-3">
                  <img src={item.featuredImage.url} alt="item.name" />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  // }
};

export default Collections;
