import React, { useEffect, useState,useContext } from 'react';
import { Redirect } from 'react-router-dom'
import { firebaseAuth } from '../../providers/AuthProvider'
import firebase from "firebase/app";
import { Link } from "react-router-dom";
import { AuthUrl} from '../../Constants';
import {Form,FormGroup,Button,Alert,Label,Input} from 'reactstrap';
import { toast } from "react-toastify";



const SignIn = ({history}) => {
  const {  inputs, setInputs, user } = useContext(firebaseAuth)
  const [redirect, setRedirect] = useState(false)
  // const [firstLoad, setFirstLoad] = useState(false)
  let useQuery = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (user.isAuthenticated)
      setRedirect(true);
  }, [user]);
  useEffect(() => {
    let pw_update = useQuery.get('pw_update');

    setInputs({ email: '', password: '', pw_update: pw_update ? true : false });
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault()
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: inputs.email, password: inputs.password })
  };
  console.log(requestOptions);
      fetch(AuthUrl + 'login', requestOptions)
      .then(async response => {
        console.log('here');

        const data = await response.json();
        // check for error response
        console.log('here');

        if (!response.ok) {
        
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            toast.error(data,{
              autoClose: false
              });
            
            return Promise.reject(error);
        }
        firebase.auth().signInWithCustomToken(data.message)
        .then(u => {
          var uid = u.user.uid;
          firebase.firestore().collection("users").where("userId","==",uid).get()
          .then(user => {
            if(user.empty) {
              firebase.firestore().collection("users")
              .doc(uid).set({
                userId: uid,
                handle:uid
              });
            }
          });    
        })
        .catch(function (error) {
          console.log('here');
          toast.error(error.message,{
            autoClose: false
            });
        });
    })
      .catch((e) => {
        console.log(e);
        toast.error(e,{
          autoClose: false
          });
      });
    }
     
      if (redirect)
      return <Redirect to="/" />;
  const handleChange = e => {
    const { id, value } = e.target
    setInputs(prev => ({ ...prev, [id]: value }))
  }

  return (
    <div className="container account-page">
      <div className="py-3"></div>
      <h1>Sign In</h1>
      <div className="py-2"></div>
      {inputs.pw_update ? <Alert variant="success">Password set successfully.  Please login below.</Alert>:""}
      <div className="py-3"></div>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Email Address</Label>
          <Input type="email" id="email" onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input type="password" id="password" onChange={handleChange} />
        </FormGroup>
        <div className="py-1"></div>
        <Button className="btn btn-primary" type="submit">Login</Button>
        <Link to="/sign-up" className="ml-4 btn btn-default">CREATE ACCOUNT</Link>
        <div className="py-1"></div>
        <div className="row">
          <div className="col-auto">
            <Link className="btn-link" to="/forgot-password">FORGOT YOUR PASSWORD?</Link>

          </div>
        </div>
        
        <div className="py-3"></div>
      </Form>
    </div>
  );
};

export default SignIn;