import React, {useContext} from 'react';
import { Redirect } from 'react-router-dom'
import {firebaseAuth} from '../../providers/AuthProvider';
import { ArrowHeadRightIcon, Spacer } from '../../Constants';
import {Button} from 'reactstrap';

const Profile = () => {
  const {user, handleSignout} = useContext(firebaseAuth)
  if(!user.isAuthenticated)
    return <Redirect to="/sign-in" />

  const handleSignOut = (e) => {
    e.preventDefault()
    handleSignout()
  } 
  return (
    <div className="container">
         <Spacer size="2" />
          <h1>Profile</h1>
          <Spacer size="1"/>
          <Button onClick={handleSignOut}><i className="light"><ArrowHeadRightIcon fill="#FFFFFF" /></i> <span>Logoff</span></Button>
    </div>
  );
};

export default Profile;