import React, { useEffect, useState } from 'react';
import {withRouter} from "react-router";
import { AuthUrl} from '../../Constants';
import {Form,FormGroup,Button,Label,Input} from 'reactstrap';
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import 'firebase/firestore';


const SignUp = () => {
  const [ inputs, setInputs] = useState({})
const history = useHistory();

  useEffect(() => {

    setInputs({ email: '', password: '', accpetsMarketing: false,confirmPassword:'',handle:'' });
  }, []);
  const handleSignUp = async e => {
    e.preventDefault();

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: inputs.email, password: inputs.password,confirmPassword:inputs.confirmPassword,handle:inputs.handle,acceptsMarketing:inputs.acceptsMarketing })
  };
  fetch(AuthUrl + 'register', requestOptions)
      .then(async response => {
        const data = await response.json();
        // check for error response
        
        if (!response.ok) {
        
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            toast.error(data,{
              autoClose: false
              });
            
            return Promise.reject(error);
        }
        toast.success("User created succesfully.",{
          autoClose: 5000
          });
        history.push("/sign-in");

        //handle good
    })
      .catch((e) => {
        toast.error(e,{
          autoClose: false
          });
      });
  };
  const handleChange = e => {
    const { id, value } = e.target
    setInputs(prev => ({ ...prev, [id]: value }));
  }

  return (
    <div>

       <div className="container account-page"> 
      <div className="py-3"></div>
      <h1>Sign Up</h1>
      <div className="py-2"></div>
      <div className="py-3"></div>
      <Form onSubmit={handleSignUp}>
        <FormGroup >
          <Label>Email Address</Label>
          <Input type="email" id="email" onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input type="password" id="password" onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <Label>Confirm Password</Label>
          <Input type="password" id="confirmPassword" onChange={handleChange} />
        </FormGroup>
        <FormGroup className="pl-0 pb-3" check>
                  <label className="form-label d-block">Accept Marketing</label>
                  <label className="checkbox-container">
                    Do you want Fret12 to send you information, newsletters, product updates, etc?
                    <input
                      type="checkbox"
                      onChange={(e) => {setInputs({
                        ...inputs,
                        [e.target.name]: e.target.checked,
                      });}}
                      id="acceptsMarketing"
                      name="acceptsMarketing"
                      value={true}
                      defaultChecked={inputs.acceptsMarketing}
                    />
                    <span className="checkmark"></span>
                  </label>
                </FormGroup>
        <div className="py-1"></div>

        <Button className="btn btn-primary" type="submit">Create Account</Button>
        <div className="py-1"></div>
        <div className="row">
          <div className="col-auto">
            <Link className="btn-link" to="/sign-in">ALREADY HAVE AN ACCOUNT?</Link>
          </div>
        </div>
        <div className="py-3"></div>
      </Form>
      </div>
    </div>
  
  );
};

export default withRouter(SignUp);