import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import firebase from "../../firebase";
import "firebase/firestore";
import "firebase/storage";
import { breakpointColumnsObj} from "../../Constants";
import Masonry from 'react-masonry-css'
import { BloodlinePost } from "./partials/BloodlinePost";

const Picks = () => {
  // const db = firebase.firestore();
  // const { user } = useContext(firebaseAuth);
  // const storage = firebase.storage().ref();
  // const { id } = useParams();
  // const [item, setItem] = useState({});
  // const [owner, setOwner] = useState(null);
  // const [cols, setCols] = useState({ media: "4", videos: "6" });
  const [feedItems, setFeedItems] = useState([]);
  // const [userItems, setUserItems] = useState([]);
  // const history = useHistory();
  useEffect(() => {
    firebase
    .firestore()
    .collection("items")
    // .where("userId", "!=", doc.data().userId)
    .where("pickCount",">",0)
    .orderBy("pickCount","desc")
    .get()
    .then((qs) => {
      if (qs.docs.length > 0) {
        let tmpItems = [];
        qs.docs.forEach((doc) => {
          let tmpItem = doc.data();
          tmpItem.id = doc.id;
          tmpItems.push(tmpItem);
        });
        setFeedItems(tmpItems);
      }
    });

  }, []);

    return (
      <div>
        <div className="py-3"></div>
        <div className="container h-100 text-center">
                   <div className="py-5"></div>
          <h1>Top Picks</h1>
          <div className="py-5"></div>
          <Masonry
  breakpointCols={breakpointColumnsObj}
  className="my-masonry-grid"
  columnClassName="my-masonry-grid_column">
{feedItems.map((item) => (
   <div className="masonry-card">
  <BloodlinePost item={{ id:item.id, featuredMedia:item.featuredMedia, brand:item.brand, model:item.model, picks:item.picks, ownership: item.ownership}} />
  </div>
))}
</Masonry>
          

        </div>
        <div className="py-5"></div>
      </div>
    );
  // }
};

export default Picks;
